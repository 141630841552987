import { gql } from '@apollo/client'

const professionalCourseFragment = gql`
  fragment professionalCourseFragment on professionalCourse {
    courseName
    testCd
    isAP
    isPreAP
    isCK
    isDigitalPortfolio
    isCapstone
    isStudioArt
    totalStudentsEnrolled
    totalStudentsRegistered
    totalExamsOrdered
    teachers {
      teacherId
      teacherName
      studentsEnrolled
      studentsRegistered
      studentsActive
      lastUnitAssessed
      lastUnitName
    }
    examOnly {
      studentsEnrolled
      studentsRegistered
    }
  }
`

const professionalSubscriptionFragment = gql`
  fragment professionalSubscriptionFragment on professionalCourseUpdate {
    lastUpdated
    educationPeriod
    orgId
    testCd
    data {
      teacherId
      lastUnitAssessed
      lastUnitName
    }
  }
`

const professionalStatusFragment = gql`
  fragment professionalStatusFragment on professionalStatus {
    school {
      principalName
      coordinatorName
      coordinatorAccessCode
      adminAccessCode
      allowedToOrder
    }
    cost {
      totalCost
    }
    examRegistrations {
      numberNotTakingExam
      numberTakingExam
      numberUndecided
      numberUnused
      numberClassesExamDecisionsNotConfirmed
      standardExamDecisionDeadline
      secondSemesterExamDecisionDeadline
      changeOrderStatus
      orderStatus
      subOrderProcessing
      regularOrderingDisplayDeadline
    }
    studentRegistrations {
      numberEnrollments
      numberStudentsEnrolled
    }
  }
`
const districtStatusFragment = gql`
  fragment districtStatusFragment on professionalStatus {
    cost {
      totalCost
    }
    examRegistrations {
      numberNotTakingExam
      numberTakingExam
      numberUndecided
      numberUnused
      numberClassesExamDecisionsNotConfirmed
    }
    studentRegistrations {
      numberEnrollments
      numberStudentsEnrolled
    }
  }
`

const districtCourseFragment = gql`
  fragment districtCourseFragment on districtCourse {
    lastUpdated
    data {
      courseName
      testCd
      isAP
      isCK
      isPreAP
      isDigitalPortfolio
      isCapstone
      isStudioArt
      schools {
        studentsEnrolled
        studentsRegistered
        studentsActive
        orgName
        orgId
      }
    }
  }
`

export const coordinatorQuery = gql`
  query getCoordinatorData($orgId: Int!, $educationPeriod: Int!, $filter: professionalFilter) {
    getCoordinatorData(orgId: $orgId, educationPeriod: $educationPeriod, filter: $filter) {
      ...professionalCourseFragment
    }
  }
  ${professionalCourseFragment}
`

export const adminQuery = gql`
  query getAdminData(
    $orgId: Int!
    $educationPeriod: Int!
    $parentOrgId: Int
    $filter: professionalFilter
  ) {
    getAdminData(
      orgId: $orgId
      educationPeriod: $educationPeriod
      parentOrgId: $parentOrgId
      filter: $filter
    ) {
      ...professionalCourseFragment
    }
  }
  ${professionalCourseFragment}
`

export const districtFundingQuery = gql`
  query getDistrictFunding($orgId: Int!, $educationPeriod: Int!) {
    getDistrictFunding(orgId: $orgId, educationPeriod: $educationPeriod) {
      orgId
      educationPeriod
      data {
        grantor {
          grantorName
        }
      }
    }
  }
`

export const coordinatorSubscription = gql`
  subscription getCoordinatorData($orgId: Int!, $educationPeriod: Int!) {
    getCoordinatorData(orgId: $orgId, educationPeriod: $educationPeriod) {
      ...professionalSubscriptionFragment
    }
  }
  ${professionalSubscriptionFragment}
`

export const adminSubscription = gql`
  subscription getAdminData($orgId: Int!, $educationPeriod: Int!) {
    getAdminData(orgId: $orgId, educationPeriod: $educationPeriod) {
      ...professionalSubscriptionFragment
    }
  }
  ${professionalSubscriptionFragment}
`

export const districtAdminSubscription = gql`
  subscription getAdminData($orgId: Int!, $educationPeriod: Int!, $parentOrgId: Int) {
    getAdminData(orgId: $orgId, educationPeriod: $educationPeriod, parentOrgId: $parentOrgId) {
      parentOrgId
      ...professionalSubscriptionFragment
    }
  }
  ${professionalSubscriptionFragment}
`

export const coordinatorStatusQuery = gql`
  query getCoordinatorStatus($orgId: Int!, $educationPeriod: Int!) {
    getCoordinatorStatus(orgId: $orgId, educationPeriod: $educationPeriod) {
      ...professionalStatusFragment
    }
  }
  ${professionalStatusFragment}
`
export const adminStatusQuery = gql`
  query getAdminStatus($orgId: Int!, $educationPeriod: Int!, $parentOrgId: Int) {
    getAdminStatus(orgId: $orgId, educationPeriod: $educationPeriod, parentOrgId: $parentOrgId) {
      ...professionalStatusFragment
    }
  }
  ${professionalStatusFragment}
`

export const districtStatusQuery = gql`
  query getDistrictStatus($orgId: Int!, $educationPeriod: Int!) {
    getDistrictStatus(orgId: $orgId, educationPeriod: $educationPeriod) {
      lastUpdated
      data {
        ...districtStatusFragment
      }
    }
  }
  ${districtStatusFragment}
`

export const districtStatusSubscription = gql`
  subscription getDistrictStatus($orgId: Int!, $educationPeriod: Int!) {
    getDistrictStatus(orgId: $orgId, educationPeriod: $educationPeriod) {
      orgId
      educationPeriod
      lastUpdated
      data {
        ...districtStatusFragment
      }
    }
  }
  ${districtStatusFragment}
`

export const districtDataQuery = gql`
  query getDistrictData($orgId: Int!, $educationPeriod: Int!) {
    getDistrictData(orgId: $orgId, educationPeriod: $educationPeriod) {
      ...districtCourseFragment
    }
  }
  ${districtCourseFragment}
`

export const districtCourseQuery = gql`
  query getDistrictCourse($orgId: Int!, $parentOrgId: Int, $educationPeriod: Int!, $testCd: Int!) {
    getDistrictCourse(
      orgId: $orgId
      parentOrgId: $parentOrgId
      educationPeriod: $educationPeriod
      testCd: $testCd
    ) {
      ...districtCourseFragment
    }
  }
  ${districtCourseFragment}
`

export const districtCourseSubscription = gql`
  subscription getDistrictData($orgId: Int!, $educationPeriod: Int!) {
    getDistrictData(orgId: $orgId, educationPeriod: $educationPeriod) {
      educationPeriod
      orgId
      testCd
      ...districtCourseFragment
    }
  }
  ${districtCourseFragment}
`

export const districtRefreshMutation = gql`
  mutation refreshDistrictData($orgId: Int!, $educationPeriod: Int!) {
    refreshDistrictData(orgId: $orgId, educationPeriod: $educationPeriod)
  }
`

export const districtInvoicesQuery = gql`
  query getDistrictInvoices($orgId: Int!) {
    getDistrictInvoices(orgId: $orgId) {
      orgId
      educationPeriodCd
      data {
        invoiceType
        invoiceAvailableDate
        invoicePDFGenerated
      }
    }
  }
`

export const districtInvoiceDownloadUrlQuery = gql`
  query getDistrictInvoiceDownloadUrl($orgId: Int!, $educationPeriod: Int!, $getUrl: Boolean!) {
    getDistrictInvoiceDownloadUrl(
      orgId: $orgId
      educationPeriod: $educationPeriod
      getUrl: $getUrl
    ) {
      invoiceDownloadUrl
    }
  }
`
