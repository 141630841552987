import { NakedButton } from '@cb/apricot-react'
import styles from './actions.module.scss'

export default ({
  children,
  onClick = null,
  ariaId,
  className = '',
  visuallyDisabled = true,
  inactive,
  light = false,
}) => (
  <NakedButton
    disabled={inactive && visuallyDisabled}
    aria-describedby={ariaId}
    className={className}
    tabIndex={inactive ? '-1' : '0'}
    onClick={inactive ? e => e.preventDefault() : onClick}
    light={light}
    small
  >
    {children}
  </NakedButton>
)
