import { gql } from '@apollo/client'

const classroomFragment = gql`
  fragment classroomFragment on enrollment {
    numResults
    numToComplete
    numToScore
    assignmentsLink
    resultsLink
    assignments {
      title
      startDate
      dueDate
      link
    }
  }
`

const enrollmentFragment = gql`
  fragment enrollmentFragment on enrollment {
    orgName
    orgId
    courseName
    testCd
    sectionName
    sectionType
    teachers
    examIntent
    examStartTime
    examEndTime
    examWindow
    enrollmentId
    joinCode
    transferCode
    studentId
    studentOrTeacherCanChangeExamIntent
    registrationDeadline
    requiresUnlocking
    adminWindowDisplayName
    isAP
    isPreAP
    isCK
    isDigitalPortfolio
    isCapstone
    isStudioArt
    isDigitalExam
    address {
      city
      state
      country
    }
    digitalApplicable
    accommodations
    appInstalled
    practiceStatus
    checkinStatus
    setupStatus
    examStatus
    makeupStatus
    isMakeupAvailable
    disableAPClassroomLink
    disableSecondaryAPClassroomLinks
    disableCourseExamLink
    ...classroomFragment
  }
  ${classroomFragment}
`

const studentEnrollmentsFragment = gql`
  fragment studentEnrollmentsFragment on studentEnrollments {
    scoreSendData {
      diCode
      userPromptType
    }
    scoreSendSaved @client
    courseEnrollments {
      ...enrollmentFragment
    }
  }
  ${enrollmentFragment}
`

export const studentEnrollmentsQuery = gql`
  query getStudentEnrollments($code: Int!) {
    getStudentEnrollments(educationPeriod: $code) {
      ...studentEnrollmentsFragment
    }
  }
  ${studentEnrollmentsFragment}
`

export const studentSubscription = gql`
  subscription getStudentEnrollments($code: Int!, $studentId: String!) {
    getStudentEnrollments(educationPeriod: $code, studentId: $studentId) {
      studentId
      educationPeriod
      lastUpdated
      data {
        testCd
        ...classroomFragment
      }
    }
  }
  ${classroomFragment}
`

export const studentInfoAndRegistrationQuery = gql`
  query getStudentInfoAndRegistration($code: Int!, $isStudent: Boolean!, $refresh: Boolean) {
    getStudentInfoAndRegistration(
      educationPeriod: $code
      isStudent: $isStudent
      refresh: $refresh
    ) {
      address {
        streetAddr1
        streetAddr2
        streetAddr3
        postalCode
        city
        stateCd
        province
        countryCd
      }
      attendingOrg {
        orgId
        orgName
        city
        stateCd
        countryCd
      }
      bestLanguageCode
      descendantTribe
      dob
      emailAddress
      enrolledRecognizedTribeInd
      enrolledTribeCd
      firstName
      gender
      grade
      guardian1EducationLevelCode
      guardian2EducationLevelCode
      hispanicOriginCodes
      lastName
      middleInitial
      missingRegInfoInd
      mobileNumber
      mobileTextMsgAllowedInd
      orgStudentId
      preferredFirstName
      raceCodes
      resetOrgInd
      studentId
    }
  }
`

export const sectionDetailsQuery = gql`
  query ($enrollmentCode: String!) {
    getSectionDetails(enrollmentCode: $enrollmentCode) {
      joinCode
      transferCode
      courseName
      sectionName
      languageSurveyRequired
      teachers
      sectionType
      sectionId
      testCd
      orgId
      address {
        streetAddr1
        streetAddr2
        streetAddr3
        zip5
        city
        state
        country
      }
      orgName
      preAPSection
    }
  }
`

export const joinCourseDetailsQuery = gql`
  query {
    getJoinCourseDetails {
      enrollmentCode @client
      livedInLanguageCountry @client
      languageRegularlySpoken @client
      sectionId @client
      isMove @client
      enrollmentId @client
    }
  }
`

export const updateStudentEnrollmentMutation = gql`
  mutation updateStudentEnrollment($code: Int!, $input: updateStudentEnrollment!) {
    updateStudentEnrollment(educationPeriod: $code, input: $input) {
      ...studentEnrollmentsFragment
    }
  }
  ${studentEnrollmentsFragment}
`

export const addStudentEnrollmentMutation = gql`
  mutation addStudentEnrollment($code: Int!, $input: addStudentEnrollment!) {
    addStudentEnrollment(educationPeriod: $code, input: $input) {
      ...studentEnrollmentsFragment
    }
  }
  ${studentEnrollmentsFragment}
`

export const saveStudentScoreSendMutation = gql`
  mutation saveStudentScoreSend(
    $educationPeriod: Int!
    $studentId: String!
    $diCode: String
    $optOut: Boolean
  ) {
    saveStudentScoreSend(
      educationPeriod: $educationPeriod
      studentId: $studentId
      diCode: $diCode
      optOut: $optOut
    ) {
      diCode
      userPromptType
    }
  }
`

export const provisionStudentPracticeExamMutation = gql`
  mutation provisionStudentPracticeExam($educationPeriod: Int!, $orgId: Int!, $testCd: Int!) {
    provisionStudentPracticeExam(educationPeriod: $educationPeriod, orgId: $orgId, testCd: $testCd)
  }
`

export const requestStudentMakeupExamMutation = gql`
  mutation requestStudentMakeupExam(
    $educationPeriod: Int!
    $testCd: Int!
    $studentId: String!
    $examWindow: String!
  ) {
    requestStudentMakeupExam(
      educationPeriod: $educationPeriod
      testCd: $testCd
      studentId: $studentId
      examWindow: $examWindow
    )
  }
`

export const studentRegistrationMutation = gql`
  mutation saveStudentRegistration(
    $code: Int!
    $input: saveStudentInfoAndRegistrationInput!
    $isRegistration: Boolean!
    $isStudent: Boolean!
  ) {
    saveStudentRegistration(
      educationPeriod: $code
      input: $input
      isRegistration: $isRegistration
      isStudent: $isStudent
    ) {
      studentId
    }
  }
`

export const studentInformationMutation = gql`
  mutation saveStudentInformation(
    $code: Int!
    $input: saveStudentInfoAndRegistrationInput!
    $isProfile: Boolean!
    $isStudent: Boolean!
    $refresh: Boolean
  ) {
    saveStudentInformation(
      educationPeriod: $code
      input: $input
      isProfile: $isProfile
      isStudent: $isStudent
      refresh: $refresh
    ) {
      studentId
    }
  }
`

export const studentPrivacyQuery = gql`
  query getStudentPrivacyRestrictions($orgId: Int!, $stateCd: String) {
    getStudentPrivacyRestrictions(orgId: $orgId, stateCd: $stateCd) {
      hideRaceField
      hideEthnicityField
    }
  }
`
