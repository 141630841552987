import { useQuery } from '@apollo/client'
import { isEmpty } from '@myap/ui-library'
import {
  districtDataQuery,
  districtCourseSubscription,
} from '../../../appsync/graphql/professional'
import { updateQueryDistrictCourse } from '../../../appsync/actions/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useDistrictCoursesQuery = ({ orgId, educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
    subscribeToMore,
    client,
  } = useQuery(districtDataQuery, {
    variables: { orgId, educationPeriod },
    skip: !orgId || !educationPeriod || otherSkipConditions,
  })
  const data = rawData?.getDistrictData ?? []
  const error = rawError ? formatAppsyncErrors(rawError) : null

  if (!isEmpty(data)) {
    subscribeToMore({
      document: districtCourseSubscription,
      variables: { orgId, educationPeriod },
      updateQuery: (prev, next) => updateQueryDistrictCourse(prev, next, 'getDistrictData', client),
    })
  }

  return { data, loading, error }
}

export default useDistrictCoursesQuery
