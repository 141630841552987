import { SelectOrgDropdown } from '@myap/ui-library'
import { setRole, setOrg, getSettings } from '../../../appsync/actions/settings'
import Consumer from '../apollo/Consumer'
import styles from './schooltoggle.module.scss'

export default Consumer(({ client, preview }) => {
  const { educationPeriod, ...userDetails } = getSettings(client)

  return (
    <div className={styles['school-toggle-container']}>
      {preview ? (
        <div className={styles.sample}>Sample School</div>
      ) : (
        <SelectOrgDropdown
          {...userDetails}
          action={r => {
            const { role, orgId, orgName } = r || {}
            setRole(client, role)
            setOrg(client, orgId)
          }}
        />
      )}
      <div className={`${styles['education-period']} cb-font-size-small cb-font-weight-bold`}>
        {educationPeriod.descr}
      </div>
    </div>
  )
})
