import CourseCard from '../../_common/coursecard/CourseCard'
import CourseSections from './CourseSections'
import ButtonLinks from './ButtonLinks'
import EventLinks from './EventLinks'
import { cardsAsGrid } from '../../_common/coursecard/utils'

export default ({ userRole, courses, preview, examDates = {} }) => {
  const columns = 2
  return cardsAsGrid(courses, columns).map((row, i) => (
    <div className="row" key={i}>
      {row.map(course => {
        const { testCd, sections, isPreAP } = course
        return (
        <CourseCard key={testCd} course={course} columns={columns} preview={preview} userRole={userRole}>
          <ButtonLinks course={course} preview={preview} />
          <CourseSections sections={sections} testCd={testCd} preview={preview} isPreAP={isPreAP} />
          <EventLinks course={course} preview={preview} examDates={examDates[testCd]} />
        </CourseCard>
      )})}
    </div>
  ))
}
