import { isMobileDevice, isChromebook, profile } from '@myap/ui-library'
import { LinkWithIcon } from '../../../_common/apricot/Icons'

const DAPButton = ({ title, subtitle, textOnChrome, textOnMobile, linkWithIcon }) => {
  const url = profile().studentDigitalAppUrl

  if (isMobileDevice() && textOnMobile)
    return (
      <p>
        <em>{textOnMobile}</em>
      </p>
    )

  if (isChromebook() && textOnChrome)
    return (
      <p>
        <em>{textOnChrome}</em>
      </p>
    )

  return (
    <>
      {linkWithIcon ? (
        <LinkWithIcon title={title} link={url} />
      ) : (
        <a href={profile().studentDigitalAppUrl} className="cb-btn cb-btn-sm cb-btn-primary">
          {title}
        </a>
      )}
      {subtitle ? <div className="cb-small-font">{subtitle}</div> : null}
    </>
  )
}

export default DAPButton
