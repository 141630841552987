import { useQuery } from '@apollo/client'
import Consumer from '../../_common/apollo/Consumer'
import TeacherRaceAndEthnicityModal from '../../teacher/registration'
import { getSettings } from '../../../appsync/actions/settings'
import { teacherRegistrationQuery } from '../../../appsync/graphql/teacher'

const TeacherDemographics = Consumer(({ client }) => {
  const {
    educationPeriod: { code },
  } = getSettings(client)
  const { data, loading } = useQuery(teacherRegistrationQuery, {
    variables: { isProfessional: true },
    client,
  })
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false)
  const { getTeacherRegistration: teacherRegistrationData = {} } = data ?? {}

  useEffect(() => {
    // If teacher has not provided R&E or has selected "do not wish to answer" and we have entered
    // a new education period, then present R&E modal
    if (
      !loading &&
      !teacherRegistrationData?.scrub &&
      teacherRegistrationData?.lastUpdatedEpc !== code &&
      teacherRegistrationData?.canCollectEthnicities
    ) {
      setOpenRegistrationModal(true)
    }
  }, [loading, teacherRegistrationData, code])

  if (openRegistrationModal) {
    return <TeacherRaceAndEthnicityModal isDismissable={false} />
  }

  return null
})

TeacherDemographics.displayName = 'TeacherDemographics'

export default TeacherDemographics
