import { isIntentYes } from '@myap/metadata'
import {
  isDateTimeWithinRange,
  manipulateDays,
  manipulateMinutes,
  isDateTimePast,
  formatDate,
} from '@myap/ui-library'
import DigitalTestingApp from './statuses/DigitalTestingApp'
import DigitalPractice from './statuses/DigitalPractice'
import ExamSetup from './statuses/ExamSetup'
import Exam from './statuses/Exam'
import ExamStarted from './statuses/ExamStarted'
import MakeupRequested from './statuses/MakeupRequested'

import styles from './enrollment.module.scss'

// const DIGITAL_AVAILABLE_DATE = '2021-04-13T03:59:00.000Z'
const FAKE_AVAILABLE_DATE = '2050-05-01T13:00:00.000Z' // INSANE FUTURE DATE
// ***************************************************
// Forcing isDigitalAppAvailable to false so digital statuses are NOT displayed
// ***************************************************
const isDigitalAppAvailable = false //window.location.search.includes('dapavailableoverride=true') || isDateTimePast(DIGITAL_AVAILABLE_DATE)

const EnrollmentStatus = ({ digitalApplicable, id, ...props }) => {
  const { isDigitalExam, examStartTime, examEndTime, makeupStatus, appInstalled } = props
  const examStartWithoutTime = formatDate(examStartTime)
  const setupAvailableDate = manipulateDays(examStartWithoutTime, -3)
  const isExamDay = isDateTimeWithinRange(
    new Date(),
    examStartWithoutTime,
    manipulateDays(examStartWithoutTime, 1)
  )
  const isExamOver = isDateTimePast(examEndTime)
  const isSubmissionGracePeriodOver = isDateTimePast(manipulateDays(examStartWithoutTime, 6))
  const isSetupAvailable = isDateTimePast(setupAvailableDate)
  const checkinAvailableDate = manipulateMinutes(examStartTime, -30)
  const isCheckinAvailable = isDateTimePast(checkinAvailableDate)
  const isTakingDigitalExam = isIntentYes(props) && isDigitalExam
  // ***************************************************
  // DigitalTestingAppComponent should NOT be called
  // ***************************************************
  const DigitalTestingAppComponent = (
    <DigitalTestingApp
      {...props}
      availableDate={FAKE_AVAILABLE_DATE}
      isDigitalAppAvailable={isDigitalAppAvailable}
    />
  )

  return (
    <div
      className={`${styles['status-container']} ${
        digitalApplicable ? styles['status-digital'] : ''
      }`}
      id={id}
    >
      <Exam
        {...props}
        // isDigitalAppAvailable={isDigitalAppAvailable}
        isCheckinAvailable={isCheckinAvailable}
        checkinAvailableDate={checkinAvailableDate}
        isExamDay={isExamDay}
        isExamOver={isExamOver}
      />
      {digitalApplicable && isDigitalAppAvailable ? (
        <div className={styles['digital-status-container']}>
          {makeupStatus ? (
            <>
              <MakeupRequested {...props} />
              {appInstalled ? <DigitalPractice {...props} /> : DigitalTestingAppComponent}
            </>
          ) : (
            <>
              {DigitalTestingAppComponent}
              {isDigitalAppAvailable ? (
                <>
                  <DigitalPractice {...props} />
                  {isTakingDigitalExam ? (
                    <ExamSetup
                      {...props}
                      isExamDay={isExamDay}
                      isSetupAvailable={isSetupAvailable}
                      setupAvailableDate={setupAvailableDate}
                      isCheckinAvailable={isCheckinAvailable}
                    />
                  ) : null}
                  {!isTakingDigitalExam ? (
                    <div className={`${styles['status-full-width']} cb-small-font`}>
                      Even though you aren't currently registered for a digital exam, you can
                      download the app and take our digital practice.
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default EnrollmentStatus
