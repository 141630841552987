import { Icon } from '@cb/apricot-react'
import { useQuery } from '@apollo/client'
import orderBy from 'lodash/orderBy'
import { Spinner } from '@myap/ui-library'
import Banner from '../_common/banner/Banner'
import CourseCards from '../teacher/coursecards/CourseCards'
import Resources from '../teacher/resources/Resources'
import SchoolToggle from '../_common/schooltoggle/SchoolToggle'
import APClassroomNavigation from '../teacher/navigation/APClassroomNavigation'
import Query from '../_common/apollo/Query'
import { getSettings } from '../../appsync/actions/settings'
import { teacherQuery, teacherRegistrationQuery } from '../../appsync/graphql/teacher'
import { getTeacherAssignments } from '../_common/content/timeline/utils'
import Notifications from '../_common/content/notifications/Notifications'
import { flagCourseTypes, bodyTitle } from '../_common/content/utils'
import ProfessionalTimeline from '../professional/timeline/Timeline'
import Timeline from '../_common/content/timeline/Timeline'
import TimelineTeaser from '../_common/content/timeline/TimelineTeaser'
import Consumer from '../_common/apollo/Consumer'
import TeacherDemographics from '../professional/userinforequests/TeacherDemographics'
import TeacherRaceAndEthnicityModal from '../teacher/registration'
import { OpenModal } from '../_common/apricot/Modal'

const userRole = 'teacher'
const anchorId = 'teacherCourseAnchor'

const TeacherApp = ({ teacherData = [], loadingTeacher = true, registrationData = {} }) => {
  const { hasPreAP, hasAP } = flagCourseTypes(teacherData)
  const title = bodyTitle({ hasAP, hasPreAP })

  return (
    <Timeline
      userRole={userRole}
      courses={teacherData}
      component={({ events = [], dates = {} }) => {
        const { examDates } = dates

        return (
          <>
            <Banner
              subHeader={<SchoolToggle />}
              navigation={
                <APClassroomNavigation courses={teacherData} hasAP={hasAP} hasPreAP={hasPreAP} />
              }
            />
            {hasAP ? <TimelineTeaser events={events} /> : null}
            <Notifications courses={teacherData} userRole={userRole} showAsDropdown={false} />
            <TeacherDemographics />
            <div id={anchorId} className="container cb-margin-top-48">
              <div className="row">
                <div className="col-md-8 col-sm 6 col-xs-12">
                  <h2 className="cb-h4 cb-margin-bottom-24">Explore {title} Classroom Resources</h2>
                </div>
                <div className="col-md-4 col-sm 6 col-xs-12">
                  {!registrationData?.scrub ? (
                    <button
                      type="button"
                      className="cb-btn cb-btn-naked cb-no-padding cb-no-outline"
                      onClick={() => OpenModal(TeacherRaceAndEthnicityModal, {})}
                    >
                      My AP Profile <Icon name="east" />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-sm-6 col-xs-12">
                  {loadingTeacher ? (
                    <Spinner spinnerId="CourseCardsSpinner" />
                  ) : (
                    <CourseCards
                      userRole={userRole}
                      courses={orderBy(teacherData, ['courseName'], ['asc'])}
                      examDates={examDates}
                    />
                  )}
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <Resources courses={teacherData} userRole={userRole} id="teacherResourcesList" />
                </div>
              </div>
              {hasAP ? (
                <ProfessionalTimeline
                  events={[...events, ...getTeacherAssignments(teacherData)]}
                  anchorId={anchorId}
                />
              ) : null}
            </div>
          </>
        )
      }}
    />
  )
}

export default Consumer(props => {
  const { client } = props
  const {
    selectedOrgId: orgId,
    educationPeriod: { code: educationPeriod },
  } = getSettings(client)
  const { data: teacherData = {}, loading: loadingTeacher } = useQuery(teacherQuery, {
    variables: { educationPeriod, orgId },
    client,
  })
  const { data: teacherRegistrationData = {}, loading: loadingTeacherRegistration } = useQuery(
    teacherRegistrationQuery,
    {
      variables: { isProfessional: true },
      client,
    }
  )

  return (
    <TeacherApp
      teacherData={teacherData?.getTeacherData}
      loadingTeacher={loadingTeacher}
      registrationData={teacherRegistrationData?.getTeacherRegistration}
    />
  )
})
