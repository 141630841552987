import Profile from './Profile'
import Register from './Register'
import Consumer from '../../_common/apollo/Consumer'
import { getStudentEnrollments } from '../../../appsync/actions/student'
import * as c from './constants'

export default Consumer(
  React.memo(props => {
    const { client } = props
    const { courseEnrollments } = getStudentEnrollments(client)
    const [step, updateStep] = useState(c.UPDATE_PROFILE_INFO)
    let Component

    // add check to make sure step value is only updated if the new value exists and is different from the previous value
    // this may not be needed, but we need to try and prevent unnecessary re-renderings
    const changeStep = newStep => {
      if (newStep && newStep !== step) updateStep(newStep)
    }

    switch (step) {
      case c.UPDATE_PROFILE_INFO:
        Component = Profile
        break
      case c.UPDATE_REGISTRATION:
        Component = Register
        break
      default:
        return null
    }

    return <Component {...props} enrollments={courseEnrollments} changeStep={changeStep} isDismissable={props.isDismissable} />
  })
)
