import { getExamState } from './utils'
import { isMakeupRequested, MAKEUP_STATUSES } from './utils'

import styles from '../enrollment.module.scss'

const MakeupRequested = props => {
  const { examStatus, makeupStatus } = props
  const { isExamInProgress, isExamComplete } = getExamState(examStatus)

  return (
    <div className={styles['exam-complete-status']}>
      <h5>{isExamInProgress ? 'Exam Answers Weren’t Submitted' : isExamComplete ? 'Exam Answer Submission Complete' : 'Exam Over'}</h5>
      {isExamInProgress ? (
        <p className="cb-small-font">
          Exam responses weren’t submitted for this exam. You’ve requested a makeup exam. If your makeup exam request is approved you'll be moved to
          the next available exam date.
        </p>
      ) : isExamComplete ? (
        <p className="cb-small-font">
          Exam responses were successfully submitted for this exam but you’ve requested a makeup exam. If your makeup exam request is approved you'll
          be moved to the next available exam date and this exam submission won’t be scored.
        </p>
      ) : (
        <p className="cb-small-font">
          This exam was never started. You’ve requested a makeup exam. If your makeup exam request is approved you'll be moved to the next available
          exam date.
        </p>
      )}
      <h6>
        <strong>{MAKEUP_STATUSES[makeupStatus]}</strong>
      </h6>
      {isMakeupRequested(makeupStatus) ? (
        <div className="cb-small-font">
          <strong>Note:</strong> We'll update your makeup status after reviewing your request.
        </div>
      ) : null}
    </div>
  )
}

export default MakeupRequested
