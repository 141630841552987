import { Modal, PrimaryButton, BlackButton, Icon } from '@cb/apricot-react'
import { Error, formatDate } from '@myap/ui-library'
import Mutation from '../../_common/apollo/Mutation'
import Consumer from '../../_common/apollo/Consumer'
import { updateStudentEnrollmentMutation } from '../../../appsync/graphql/student'
import { updateStudentEnrollments } from '../../../appsync/actions/student'
import { getSettings } from '../../../appsync/actions/settings'
import { useAnalyticsStudentPreRegChangeEvent } from '../../../hooks/analyticsHooks'
import { SUCCESS, SELECTED_NO, SELECTED_YES, STATUS_UNDECIDED } from './constants'

import styles from './register.module.scss'

function Register({ courseName, orgName, examStartTime, updateChoice, choice, error }) {
  const isYes = choice === SELECTED_YES
  const srText = <span className="sr-only">selected</span>

  return (
    <div className={styles['register-options']}>
      <p>
        You are registered to take the {courseName}
        {examStartTime ? ` exam in ${formatDate(examStartTime, 'MMM yyyy')}, ` : ' assessment '}
        given by {orgName}.
      </p>
      <div className={styles['register-choice-container']}>
        <button
          type="button"
          className={`cb-hover ${isYes ? styles.selected : null}`}
          onClick={() => updateChoice(SELECTED_YES)}
        >
          {isYes ? <Icon name="check" color="green3" /> : null} Confirm Registration{' '}
          {isYes ? srText : null}
        </button>
        <button
          type="button"
          className={!isYes ? styles.selected : null}
          onClick={() => updateChoice(SELECTED_NO)}
        >
          {!isYes ? <Icon name="x-mark" color="red1" /> : null} No, I wish to remove myself from
          taking this AP {examStartTime ? 'exam' : 'assessment'} {!isYes ? srText : null}
        </button>
      </div>
      {error ? <Error message={error} /> : null}
    </div>
  )
}

function UpdateEnrollment(props) {
  const {
    client,
    update,
    data,
    loading,
    error,
    changeStep,
    examIntent,
    examStartTime,
    enrollmentId,
  } = props
  const [choice, updateChoice] = useState(
    examIntent === STATUS_UNDECIDED ? SELECTED_YES : examIntent
  )
  const [open, setOpen] = useState(true)
  const {
    educationPeriod: { code },
  } = getSettings(client)

  useAnalyticsStudentPreRegChangeEvent({ examIntent })

  const handleCancelClick = () => setOpen(false)

  return (
    <Modal
      open={open}
      title={`Register for ${examStartTime ? 'Exam' : 'Assessment'}`}
      shadowRoot
      modalNode
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <BlackButton
            className="cb-margin-right-16"
            disabled={loading}
            onClick={handleCancelClick}
          >
            Cancel
          </BlackButton>
          <PrimaryButton
            disabled={!choice || loading || choice === examIntent}
            loading={loading}
            onClick={async () => {
              const { data: updatedData } = await update({
                variables: { code, input: { enrollmentId, examIntent: choice } },
              })
              if (updatedData) {
                changeStep(SUCCESS)
              }
            }}
          >
            Confirm
          </PrimaryButton>
        </div>
      }
    >
      <Register {...props} updateChoice={updateChoice} choice={choice} error={error} />
    </Modal>
  )
}

export default Consumer(props => {
  const { changeStep, examIntent, examStartTime, enrollmentId, client } = props

  return (
    <Mutation
      mutation={updateStudentEnrollmentMutation}
      update={(cache, response) =>
        updateStudentEnrollments(cache, response, 'updateStudentEnrollment')
      }
      Component={({ update, data, meta: { loading, error } }) => (
        <UpdateEnrollment update={update} data={data} loading={loading} error={error} {...props} />
      )}
    />
  )
})
