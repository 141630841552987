import { setSelectedOrgId, isEmpty } from '@myap/ui-library'
import { isStudent } from '@myap/metadata'
import { userSettingsQuery } from '../graphql/settings'

export const getSettings = client => {
  const {
    getUserSettings: { userDetails, educationPeriods, systemDates, inactiveFeatures },
  } = client.readQuery({ query: userSettingsQuery })
  const educationPeriod =
    educationPeriods.length === 1
      ? educationPeriods[0]
      : educationPeriods.find(edpd => edpd.chronology === 'current')
  return {
    ...userDetails,
    educationPeriods,
    educationPeriod,
    systemDates,
    inactiveFeatures,
  }
}

const updateUserDetails = (client, update) => {
  const settings = client.readQuery({ query: userSettingsQuery })
  client.writeQuery({
    query: userSettingsQuery,
    data: {
      ...settings,
      getUserSettings: {
        ...settings.getUserSettings,
        userDetails: {
          ...settings.getUserSettings.userDetails,
          ...update,
        },
      },
    },
  })
}

// Will set hasEnrollments to true for student in current education period.
export const updateStudentHasEnrollments = client => {
  const settings = client.readQuery({ query: userSettingsQuery })
  if (isStudent(settings.getUserSettings.userDetails.selectedRole)) {
    client.writeQuery({
      query: userSettingsQuery,
      data: {
        ...settings,
        getUserSettings: {
          ...settings.getUserSettings,
          educationPeriods: settings.getUserSettings.educationPeriods.map(edpd => {
            if (edpd.chronology === 'current') {
              return { ...edpd, hasEnrollments: true }
            }
            return { ...edpd }
          }),
        },
      },
    })
  }
}

// Will set hasEnrollments to true for student in current education period.
export const updateStudentIsRegistered = client => {
  const settings = client.readQuery({ query: userSettingsQuery })
  if (isStudent(settings.getUserSettings.userDetails.selectedRole)) {
    client.writeQuery({
      query: userSettingsQuery,
      data: {
        ...settings,
        getUserSettings: {
          ...settings.getUserSettings,
          educationPeriods: settings.getUserSettings.educationPeriods.map(edpd => {
            if (edpd.chronology === 'current') {
              return { ...edpd, isRegistered: true }
            }
            return { ...edpd }
          }),
        },
      },
    })
  }
}

export const setRole = (client, selectedRole) => updateUserDetails(client, { selectedRole })

export const setOrg = (client, selectedOrgId) => {
  setSelectedOrgId(selectedOrgId)
  updateUserDetails(client, { selectedOrgId })
}

// used for students only
// if they have not previously registered, personId will not be present
// update personId after successful registration
export const setPersonId = (client, personId) => updateUserDetails(client, { personId })
