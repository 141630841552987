import { profile } from '@myap/ui-library'
import ToggleInactiveLink from './ToggleInactiveLink'

export default ({ link, title = 'AP Classroom', children, ariaId, className, visuallyDisabled = true }) => (
  <ToggleInactiveLink
    href={`${profile().classroomUrl}/${link || ''}`}
    inactive={link === '#'}
    ariaId={ariaId}
    className={className}
    visuallyDisabled={visuallyDisabled}
  >
    {children || title}
  </ToggleInactiveLink>
)
