import { profile } from '@myap/ui-library'
import DAPButton from './DAPButton'
import MakeupButton from './MakeupButton'
import { IconExclamation } from '../../../_common/apricot/Icons'
import { getExamState } from './utils'

import styles from '../enrollment.module.scss'

const DAP_TITLE = 'Return to App'

const ExamComplete = props => (
  <>
    <h5>Answer Submission Complete</h5>
    <p>
      To see when your scores will be available, visit <a href={`${profile().apstudentsUrl}/get-scores`}>Get Your AP Scores</a>.
    </p>
    <Makeup {...props} />
  </>
)

const Makeup = props =>
  props.isMakeupAvailable ? (
    <p>
      {props.text ? props.text : 'If you would like to test again'}, <MakeupButton title="request a makeup" {...props} />.
    </p>
  ) : (
    <p>To request a makeup, please contact your AP coordinator if your school is still in session or call AP Services for Students.</p>
  )

const ExamInProgress = props => (
  <>
    <h5>Exam Started</h5>
    <DAPButton title={DAP_TITLE} />
    <Makeup {...props} />
  </>
)

const ExamNotStarted = props => (
  <>
    <h5>Exam Over</h5>
    <Makeup {...props} text="If you would like to take the test" />
  </>
)

const ExamSubmissionPending = props => (
  <>
    <h5>
      <div>
        <IconExclamation /> Answer Submission Incomplete
      </div>
    </h5>
    {!props.isSubmissionGracePeriodOver ? (
      <>
        <p>Make sure you're connected to the internet, relaunch the app, and try your submission again.</p>
        <DAPButton title={DAP_TITLE} />
      </>
    ) : null}
    <Makeup {...props} />
  </>
)

const ExamStarted = props => {
  const { isDuringExam, isExamOver, examStatus } = props
  const { isExamInProgress, isExamComplete } = getExamState(examStatus)

  return (
    <div className={`${styles['inprogress-status']}`}>
      {isExamComplete ? (
        <ExamComplete {...props} />
      ) : isDuringExam ? (
        <ExamInProgress {...props} />
      ) : isExamInProgress && isExamOver ? (
        <ExamSubmissionPending {...props} />
      ) : (
        <ExamNotStarted {...props} />
      )}
    </div>
  )
}

export default ExamStarted
