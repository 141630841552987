import orderBy from 'lodash/orderBy'
import { Icon, NakedButton } from '@cb/apricot-react'
import { profile, numberFormat } from '@myap/ui-library'
import Table from '../apricot/Table'
import Consumer from '../apollo/Consumer'
import LastUpdated from '../lastupdated/LastUpdated'
import Query from '../apollo/Query'
import { setOrg, getSettings } from '../../../appsync/actions/settings'
import { districtCourseQuery } from '../../../appsync/graphql/professional'

import styles from './courseaccordion.module.scss'

const getTotal = (arr, key) => numberFormat(arr.reduce((sum, item) => sum + (item[key] || 0), 0))
const changeToAdminView = (client, orgId) => setOrg(client, orgId)

const SchoolTable = ({ data, client, educationPeriod, orgId }) => {
  const { data: schoolData, lastUpdated } = data ?? {}
  const { schools, testCd, isPreAP } = schoolData ?? {}
  const { classroomUrl, aproUrl } = profile()
  const sortedSchools = orderBy(schools, ['orgName'], ['asc'])

  const HeaderLink = ({ title, link }) => (
    <a href={link} className={styles['table-header-link']}>
      <span>{title}</span>
      <Icon name="east" />
    </a>
  )

  const BodyBuilder = () =>
    sortedSchools.map((d, i) => {
      const { studentsEnrolled, studentsRegistered, studentsActive, orgName, orgId } = d

      return (
        <tr key={`${orgId}_${testCd}_${i}`}>
          <th scope="row">
            <NakedButton onClick={() => changeToAdminView(client, orgId)}>{orgName}</NakedButton>
          </th>
          <td>{numberFormat(studentsEnrolled)}</td>
          {!isPreAP ? <td>{numberFormat(studentsRegistered)}</td> : null}
          <td>{numberFormat(studentsActive)}</td>
        </tr>
      )
    })

  const Totals = () => (
    <tr className={styles.totals}>
      <th scope="row">District Total</th>
      <td>{getTotal(schools, 'studentsEnrolled')}</td>
      {!isPreAP ? <td>{getTotal(schools, 'studentsRegistered')}</td> : null}
      <td>{getTotal(schools, 'studentsActive')}</td>
    </tr>
  )

  const TIER_HEADER = [
    { title: 'Organizations', srOnly: true },
    {
      title: (
        <HeaderLink
          title="AP Registration & Ordering"
          link={`${aproUrl}/students?crse=${testCd}`}
        />
      ),
      colspan: isPreAP ? 1 : 2,
    },
    {
      title: !isPreAP ? (
        <HeaderLink
          title="AP Classroom Progress Dashboard"
          link={`${classroomUrl}/${testCd}/dashboard_redirect?educationPeriod=${educationPeriod}&districtId=${orgId}`}
        />
      ) : (
        <HeaderLink
          title="Pre-AP Classroom Usage Dashboard"
          link={`${classroomUrl}/${testCd}/reports/usage/assessment`}
        />
      ),
      colspan: 1,
    },
  ]

  const HEADER_ENROLL = { title: 'Student Enrollments' }
  const HEADER_REG = { title: 'Exam Registrations' }
  const HEADER_ACTIVE = { title: !isPreAP ? 'Students Active' : 'Students Accessed Course' }

  return sortedSchools.length ? (
    <>
      <Table
        tierHeader={TIER_HEADER}
        header={
          isPreAP ? [HEADER_ENROLL, HEADER_ACTIVE] : [HEADER_ENROLL, HEADER_REG, HEADER_ACTIVE]
        }
        tbody={
          <>
            <Totals />
            <BodyBuilder />
          </>
        }
      />
      <LastUpdated lastUpdated={lastUpdated} />
    </>
  ) : (
    <p>
      <em>No sections have been created for this course.</em>
    </p>
  )
}

export default Consumer(props => {
  const { client, testCd } = props
  const {
    selectedOrgId: orgId,
    roles,
    educationPeriod: { code: educationPeriod },
  } = getSettings(client)

  const { parentOrgId: orgParentId } = roles.find(r => r.orgId === orgId) ?? {}
  const parentOrgId = orgParentId && orgParentId !== orgId ? { parentOrgId: orgParentId } : {}
  const variables = { educationPeriod, orgId, testCd, ...parentOrgId }

  return (
    <Query
      query={districtCourseQuery}
      variables={variables}
      requestStatusesEnabled={true}
      Component={props => (
        <SchoolTable {...props} educationPeriod={educationPeriod} orgId={orgId} />
      )}
    />
  )
})
