import { useId } from 'react'
import { Formik, Form } from 'formik'
import { Icon, PrimaryButton, Toasts, Toast, Spinner } from '@cb/apricot-react'
import { TEACHER_ETHNICITY_OPTIONS, TEACHER_RACE_OPTIONS } from '@myap/metadata'
import { Error, ChooseRaceEthnicity, isEmpty } from '@myap/ui-library'
import { useMutation, useQuery } from '@apollo/client'
import {
  teacherRegistrationMutation,
  teacherRegistrationQuery,
} from '../../../appsync/graphql/teacher'
import Consumer from '../../_common/apollo/Consumer'
import { updateTeacherRegistration } from '../../../appsync/actions/teacher'
import { getSettings } from '../../../appsync/actions/settings'
import {
  DEFAULT_TEACHER_VALUES,
  REQUIRED_TEACHER_VALUES,
  FIELD_RACE,
  FIELD_ETHNICITY,
  FIELD_RECOGNIZED_TRIBE,
  FIELD_ENROLLED_TRIBE,
  FIELD_DESCENDANT_TRIBE,
} from './constants'
import { getShadowRootNode } from '../../../utilities/dom'

import styles from '../../student/profileandreg/profileandregform.module.scss'

const TeacherRegistrationForm = Consumer(({ client, setOpen, adjustHeight, isDismissable }) => {
  const modalHeaderId = useId()
  const {
    educationPeriod: { code },
    personId,
  } = getSettings(client)
  const educationPeriodData = { selectedEducationPeriod: code }
  const [initialValues, setInitialValues] = useState({
    ...DEFAULT_TEACHER_VALUES,
  })
  const variables = { isProfessional: true }
  const [showToast, setShowToast] = useState(false)
  let toastTimerId = null
  const [update, { loading: saving, error: mutationError }] = useMutation(
    teacherRegistrationMutation,
    { client }
  )
  const {
    data,
    loading,
    error: queryError,
  } = useQuery(teacherRegistrationQuery, {
    variables,
    client,
  })

  useEffect(() => {
    let isCurrent = true

    if (isCurrent && !loading && adjustHeight) {
      setTimeout(() => adjustHeight(), 250)
    }
    return () => {
      isCurrent = false
    }
  }, [loading])

  useEffect(() => {
    if (!loading) {
      const { getTeacherRegistration = {} } = data || {}
      const keys = getTeacherRegistration ? Object.keys(getTeacherRegistration) : []
      setInitialValues({
        ...DEFAULT_TEACHER_VALUES,
        ...keys.reduce(
          (obj, key) =>
            !isEmpty(getTeacherRegistration[key])
              ? { ...obj, [key]: getTeacherRegistration[key] }
              : obj,
          {}
        ),
      })
    }
  }, [data, loading])

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      validate={values => {
        const errors = {}
        if (!values[FIELD_RACE]?.length) {
          errors[FIELD_RACE] = 'At least one option must be selected'
        }
        if (!values[FIELD_ETHNICITY]?.length) {
          errors[FIELD_ETHNICITY] = 'At least one option must be selected'
        }
        if (
          Array.isArray(values[FIELD_RACE]) &&
          values[FIELD_RACE].find(v => v == TEACHER_RACE_OPTIONS.nativeAmerican.value) &&
          values[FIELD_RECOGNIZED_TRIBE] === null
        ) {
          errors[FIELD_RECOGNIZED_TRIBE] = 'Field is required'
        }
        return errors
      }}
      onSubmit={async (values, { resetForm, setStatus }) => {
        if (saving) return
        setStatus('')
        try {
          const {
            [FIELD_RECOGNIZED_TRIBE]: enrolledRecognizedTribeInd,
            [FIELD_ENROLLED_TRIBE]: enrolledTribeCd,
            [FIELD_DESCENDANT_TRIBE]: descendantTribe,
            lastUpdatedEpc, // should not be sent in payload
            userId, // should not be sent in payload
            canCollectEthnicities, // should not be sent in payload
            scrub, // should not be sent in payload
            __typename,
            ...saveable
          } = values // filter out UI only fields and Native American tribe fields
          // We don't want to include Native American tribe fields if raceCode 1 is not selected
          let tribeFields = {}
          let unsentTribeFields = {}
          if (
            Array.isArray(values[FIELD_RACE]) &&
            values[FIELD_RACE].some(v => v == TEACHER_RACE_OPTIONS.nativeAmerican.value)
          ) {
            tribeFields = { enrolledRecognizedTribeInd, enrolledTribeCd, descendantTribe }
          } else {
            unsentTribeFields = {
              [FIELD_RECOGNIZED_TRIBE]: null,
              [FIELD_ENROLLED_TRIBE]: null,
              [FIELD_DESCENDANT_TRIBE]: null,
            }
          }
          const updateObj = {
            variables: {
              ...variables,
              input: {
                ...saveable,
                ...tribeFields,
              },
            },
          }
          await update(updateObj)
          const savedUpdateObject =
            Object.keys(tribeFields).length > 0
              ? {
                  ...saveable,
                  ...tribeFields,
                  lastUpdatedEpc: educationPeriodData.selectedEducationPeriod,
                  userId,
                  canCollectEthnicities,
                  scrub,
                }
              : {
                  ...saveable,
                  ...unsentTribeFields,
                  lastUpdatedEpc: educationPeriodData.selectedEducationPeriod,
                  userId,
                  canCollectEthnicities,
                  scrub,
                }
          await updateTeacherRegistration({ client, variables, data: savedUpdateObject })
          // resetForm(values)
          if (!isDismissable) {
            setOpen(false)
          } else {
            setShowToast(true)
            toastTimerId = setTimeout(() => setShowToast(false), 5000)
          }
        } catch (err) {
          setStatus('error')
          console.error('Error saving teacher registration', err)
        }
      }}
    >
      {props => {
        const {
          isValid,
          isSubmitting,
          submitForm,
          status,
          setFieldValue,
          setTouched,
          handleBlur,
          handleChange,
          setFieldTouched,
          values,
          dirty,
        } = props
        const disableForm = loading || isSubmitting

        useEffect(() => {
          if (status === 'error') {
            const errorEl = getShadowRootNode().querySelector('#formError')
            errorEl.scrollIntoView(true)
          }
        }, [status])

        if (loading) {
          return (
            <div id="modalContent" className="cb-modal-content">
              <Spinner spinnerId="profileFormSpinner" size="64" />
            </div>
          )
        }

        return (
          <>
            <div className="cb-modal-header cb-modal-has-close">
              <h2 className="cb-modal-title" id={`apricot_modal_header_${modalHeaderId}`}>
                {isDismissable ? 'Your Profile' : 'Complete your profile'}
              </h2>
              {isDismissable ? (
                <button
                  type="button"
                  className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
                  aria-describedby={`apricot_modal_header_${modalHeaderId}`}
                  data-cb-modal-close="true"
                >
                  <span />
                  <Icon name="x-mark" />
                  <span className="sr-only">Close Modal</span>
                </button>
              ) : null}
            </div>
            <div id="modalContent" className="cb-modal-content">
              <Toasts bottom>
                <Toast
                  timeout={5000}
                  type="success"
                  message="Profile saved"
                  controlled={true}
                  show={showToast}
                  onDismiss={() => {
                    clearTimeout(toastTimerId)
                    setShowToast(false)
                  }}
                />
              </Toasts>
              <Form id="teacherRegistrationForm" className={styles.registration}>
                <ChooseRaceEthnicity
                  values={values}
                  initialValues={initialValues}
                  disabled={disableForm}
                  isSubmitting={isSubmitting}
                  client={client}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  setFieldTouched={setFieldTouched}
                  setTouched={setTouched}
                  handleBlur={handleBlur}
                  isCurrentYear={true}
                  ethnicityOptions={TEACHER_ETHNICITY_OPTIONS}
                  raceOptions={TEACHER_RACE_OPTIONS}
                  requiredValues={REQUIRED_TEACHER_VALUES}
                  requiresPrivacyCheck={false}
                  isLoading={loading}
                />
                {status === 'error' ? (
                  <div id="formError">
                    <Error title="Error" className="cb-margin-bottom-16">
                      There was an error saving your information. Please try again later.
                    </Error>
                  </div>
                ) : null}
              </Form>
            </div>
            <div className="cb-modal-footer">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginRight: 'auto' }}>
                  <p className="cb-margin-bottom-16">
                    Before clicking &#34;Save,&#34; confirm that all information entered on this
                    form is accurate.
                  </p>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <PrimaryButton
                    disabled={isSubmitting || (!dirty && isDismissable)}
                    loading={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
})

TeacherRegistrationForm.displayName = 'Teacher Registration Form'

export default TeacherRegistrationForm
