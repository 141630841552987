import { formatDate, isChromebook, isMobileDevice } from '@myap/ui-library'
import { Checkmark, QuestionTooltip, Alert } from '../../../_common/apricot/Icons'
import DAPButton from './DAPButton'
import { getSetupState } from './utils'

const CHROMEBOOK_TEXT = 'Launch the app to complete exam setup'

const ExamSetup = ({
  setupStatus,
  appInstalled,
  isSetupAvailable,
  setupAvailableDate,
  isCheckinAvailable,
  isExamDay,
}) => {
  const { isSetupComplete } = getSetupState(setupStatus)
  const prompt = isChromebook()
    ? CHROMEBOOK_TEXT
    : isMobileDevice()
    ? "Download the app on the device you're testing on to complete exam setup."
    : 'Download the app to complete exam setup'

  return (
    <div>
      <h5>
        Exam Setup{' '}
        <QuestionTooltip>
          <p>
            Complete exam setup 1-3 days before this digital exam date. If you’re taking more than
            one exam, remember that you need to complete exam setup for each digital exam subject
            you plan to take.
          </p>
        </QuestionTooltip>
      </h5>
      <div>
        {!isSetupAvailable ? (
          <>Available {formatDate(setupAvailableDate, 'MMMM d')}</>
        ) : !appInstalled ? (
          isExamDay && !isCheckinAvailable ? (
            <Alert text={prompt} offset />
          ) : (
            <em>{prompt}</em>
          )
        ) : isSetupComplete ? (
          <>
            Complete <Checkmark />
          </>
        ) : (
          <DAPButton
            title="Start Exam Setup"
            textOnMobile="Open the app on the device you're testing on to complete exam setup."
            textOnChrome={CHROMEBOOK_TEXT}
          />
        )}
      </div>
    </div>
  )
}
export default ExamSetup
