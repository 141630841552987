import { CMS_TEACHER } from '@myap/metadata'
import CourseCardHeader from './CourseCardHeader'
import styles from './coursecard.module.scss'

export default ({ course, userRole, subtitle, preview, columns, children }) => {
  const { testCd, isPreAP } = course
  const colWidth = Math.floor(12 / columns)
  return (
    <>
      <div className={`col-md-${colWidth} col-sm-12 col-xs-12`}>
        <div className={`${styles['course-card']} ${styles['course-' + testCd]}`}>
          <CourseCardHeader course={course} subtitle={subtitle} preview={preview} />
          {!isPreAP || userRole === CMS_TEACHER ? (
            <div className={styles.content}>{children}</div>
          ) : null}
        </div>
      </div>
    </>
  )
}
