const STATUS_COMPLETE = 'COMPLETE'
const STATUS_INCOMPLETE = 'IN_PROGRESS'

const getStatus = (status, id) => ({
  [`is${id}Complete`]: status === STATUS_COMPLETE,
  [`is${id}InProgress`]: status === STATUS_INCOMPLETE,
})

export const getPracticeState = status => getStatus(status, 'Practice')

export const getSetupState = status => getStatus(status, 'Setup')

export const getExamState = status => getStatus(status, 'Exam')

export const getCheckInState = status => getStatus(status, 'Checkin')

export const MAKEUP_STATUS_REQUESTED = 'REQUESTED'
export const MAKEUP_STATUS_DENIED = 'DENIED'
export const MAKEUP_STATUS_APPROVED = 'APPROVED'

export const MAKEUP_STATUSES = {
  [MAKEUP_STATUS_REQUESTED]: 'Makeup Request Received',
  [MAKEUP_STATUS_DENIED]: 'Makeup Request Denied',
  [MAKEUP_STATUS_APPROVED]: 'Makeup Request Approved',
}

export const isMakeupRequested = status => status === MAKEUP_STATUS_REQUESTED
