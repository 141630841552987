import { ErrorNotification } from '@cb/apricot-react'
import orderBy from 'lodash/orderBy'
import { CMS_STUDENT } from '@myap/metadata/dist/user'
import { Spinner } from '@myap/ui-library'
import Banner from '../student/banner/Banner'
import CourseCards from '../student/coursecards/CourseCards'
import Notifications from '../_common/content/notifications/Notifications'
import StudentRegistrationNeeded from '../student/userinforequests/StudentRegistrationNeeded'
import Resources from '../student/resources/Resources'
import Timeline from '../student/timeline/Timeline'
import DashboardAnalytics from '../student/dashboard/DashboardAnalytics'
import Consumer from '../_common/apollo/Consumer'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import useStudentEnrollmentsQuery from '../student/hooks/useStudentEnrollmentsQuery'
// import useStudentEnrollmentsSubscription from '../student/hooks/useStudentEnrollmentsSubscription'
import { setStudentEnrollments, updateQueryStudent } from '../../appsync/actions/student'
import ScoreSend from '../student/scoresend/ScoreSend'
import { ON_PAGE_TOP, ON_PAGE_BOTTOM } from '../student/scoresend/constants'

const StudentApp = React.memo(({ data, showTransitionContent }) => {
  const { courseEnrollments } = data || {}
  const enrollments = courseEnrollments || []
  const courseCardContainerId = 'studentCourseCards'

  return (
    <>
      <DashboardAnalytics />
      <Notifications courses={enrollments} userRole={CMS_STUDENT} showAsDropdown={false} />
      <StudentRegistrationNeeded />
      <Timeline enrollments={enrollments} anchorId={courseCardContainerId} />
      <div className="container cb-margin-top-48" id={courseCardContainerId} tabIndex="-1">
        <ScoreSend position={ON_PAGE_TOP} {...data} />
        {!showTransitionContent ? (
          <>
            <div className="row">
              <div className="col-xs-12">
                <h2 className="cb-h4 cb-margin-bottom-24">Courses & Assignments</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <CourseCards courses={orderBy(enrollments, ['courseName'], ['asc'])} />
              </div>
            </div>
          </>
        ) : null}
        <ScoreSend position={ON_PAGE_BOTTOM} {...data} />
        <Resources enrollments={enrollments} hideJoinCourse={showTransitionContent} />
      </div>
    </>
  )
})

export default Consumer(({ client }) => {
  // Get user details
  const {
    loading: loadingUserDetails,
    userDetails: { personId: studentId },
    educationPeriod: { code: educationPeriod, isTransitionPeriod, hasEnrollments },
  } = useUserSettingsQuery()

  // Get student enrollments
  const {
    data: enrollmentsData,
    loading: loadingEnrollments,
    error: errorEnrollments,
  } = useStudentEnrollmentsQuery({ educationPeriod, otherSkipConditions: !hasEnrollments })

  // Subscribe to student enrollments updates
  // const {
  //   data: enrollmentsData,
  //   loading: loadingEnrollments,
  //   error: errorEnrollments,
  // } = useStudentEnrollmentsSubscription({
  //   studentId,
  //   educationPeriod,
  //   otherSkipConditions: !hasEnrollments,
  // })

  useEffect(() => {
    if (!loadingUserDetails && !studentId) {
      // Set some default data
      setStudentEnrollments(client)
    }
  }, [studentId])

  if (errorEnrollments) {
    return <ErrorNotification>{errorEnrollments}</ErrorNotification>
  }

  if (loadingEnrollments) {
    return <Spinner spinnerId="districtSpinner" size="64" />
  }

  return (
    <>
      <Banner />
      {studentId ? (
        <StudentApp data={enrollmentsData} showTransitionContent={isTransitionPeriod} />
      ) : (
        <StudentApp showTransitionContent={isTransitionPeriod} />
      )}
    </>
  )
})
