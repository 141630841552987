import { useMutation } from '@apollo/client'
import { NakedButton } from '@cb/apricot-react'
import { STUDENT_EXAM_URLS, formatDate } from '@myap/ui-library'
import { OpenModal, Modal } from '../../../_common/apricot/Modal'
import { Checkmark, LinkWithIcon } from '../../../_common/apricot/Icons'
import { getStudentPracticeResults } from '../../../../rest/s3'
import Consumer from '../../../_common/apollo/Consumer'
import { getSettings } from '../../../../appsync/actions/settings'
import { provisionStudentPracticeExamMutation } from '../../../../appsync/graphql/student'
import DAPButton from './DAPButton'
import { getPracticeState } from './utils'

import styles from '../enrollment.module.scss'

const ResetButton = Consumer(({ client, testCd, orgId, downloadLink, pdfName }) => {
  const {
    educationPeriod: { code },
  } = getSettings(client)
  const triggerRef = useRef(null)
  const [resetDisabled, setResetToDisabled] = useState(false)

  return (
    <NakedButton
      disabled={resetDisabled}
      onClick={() =>
        OpenModal(
          ProvisionPractice,
          {
            testCd,
            orgId,
            code,
            downloadLink,
            pdfName,
            onComplete: () => setResetToDisabled(true),
          },
          triggerRef.current
        )
      }
    >
      Reset Digital Practice
    </NakedButton>
  )
})

const PracticeNotComplete = ({ isInProgress, testCd, orgId }) => (
  <>
    <DAPButton
      linkWithIcon
      title="Launch Digital Practice"
      subtitle={
        isInProgress
          ? 'Digital Practice is in progress. If you just completed, check back in a few minutes to view your responses.'
          : null
      }
      textOnChrome="Launch app to take practice."
      textOnMobile="Open the app on the device you're testing on to launch digital practice."
    />
    <ResetButton testCd={testCd} orgId={orgId} />
  </>
)

const ProvisionPractice = ({
  testCd,
  orgId,
  code: educationPeriod,
  downloadLink,
  pdfName,
  onComplete,
}) => {
  const [provisionPractice, { loading, error, data }] = useMutation(
    provisionStudentPracticeExamMutation,
    {
      variables: { testCd, orgId, educationPeriod },
    }
  )

  return data ? null : (
    <Modal
      body={
        <>
          <p>
            Please note that the questions for digital practice are the same ones you previously
            completed.{' '}
            {downloadLink ? (
              <>
                You can download the{' '}
                <a href={downloadLink} download={pdfName}>
                  responses to your latest completed digital practice
                </a>
                .
              </>
            ) : null}
          </p>
          <p>
            Once you've reset, open the app to access your reset practice. It may take a few minutes
            for the digital testing app to update. If you have a practice in progress, it will be
            reset.
          </p>
          {error ? (
            <p className="cb-error-msg">
              An error occurred resetting your digital practice. Please try again.
            </p>
          ) : null}
        </>
      }
      title="Reset Digital Practice?"
      buttons={[
        {
          title: 'Cancel',
          dismiss: true,
          disabled: loading,
        },
        {
          title: 'Reset Digital Practice',
          primary: true,
          disabled: loading,
          busy: loading,
          click: () => {
            provisionPractice()
            onComplete && onComplete()
          },
        },
      ]}
    />
  )
}

const PracticeComplete = Consumer(({ client, testCd, courseName, orgId }) => {
  const {
    educationPeriod: { code },
  } = getSettings(client)
  const [downloadData, setDownloadData] = useState({})
  const pdfName = `MyLatestAnwsers-${courseName.replace(/ /g, '')}-${formatDate(
    downloadData.date,
    'MMMdd'
  )}.pdf`

  useEffect(() => {
    getStudentPracticeResults(code, testCd, setDownloadData)
  }, [])

  return (
    <>
      <div>
        Complete <Checkmark />
      </div>
      {downloadData.link ? (
        <>
          <LinkWithIcon
            icon="note"
            leftAlignIcon={true}
            title="Your Latest Answers"
            link={downloadData.link}
            download={pdfName}
          />
          <LinkWithIcon
            icon="note"
            leftAlignIcon={true}
            title="Scoring Materials"
            link={STUDENT_EXAM_URLS[testCd]}
            newWindow={true}
          />
        </>
      ) : (
        <p className="cb-small-font">
          Processing your responses now. They'll be available shortly.
        </p>
      )}
      <ResetButton
        testCd={testCd}
        orgId={orgId}
        downloadLink={downloadData.link}
        pdfName={pdfName}
      />
    </>
  )
})

const DigitalPractice = ({
  practiceStatus,
  appInstalled,
  testCd,
  orgId,
  courseName,
  isExamOver,
}) => {
  const { isPracticeComplete, isPracticeInProgress } = getPracticeState(practiceStatus)

  return (
    <div className={styles['practice-status']}>
      <h5>Digital Practice</h5>
      {!appInstalled ? (
        <em>Download the app to launch digital practice.</em>
      ) : isPracticeComplete ? (
        <PracticeComplete testCd={testCd} orgId={orgId} courseName={courseName} />
      ) : (
        <PracticeNotComplete isInProgress={isPracticeInProgress} testCd={testCd} orgId={orgId} />
      )}
    </div>
  )
}

export default DigitalPractice
