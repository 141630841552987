import { useMutation } from '@apollo/client'
import { NakedButton } from '@cb/apricot-react'
import { getSettings } from '../../../../appsync/actions/settings'
import { requestStudentMakeupExamMutation } from '../../../../appsync/graphql/student'
import { OpenModal, Modal } from '../../../_common/apricot/Modal'
import Consumer from '../../../_common/apollo/Consumer'
import { saveMakeupRequestedStatus } from '../../../../appsync/actions/student'

const RequestMakeupModal = Consumer(({ client, testCd, examWindow, enrollmentId }) => {
  const {
    educationPeriod: { code: educationPeriod },
    personId: studentId,
  } = getSettings(client)
  const [request, { data, loading, error }] = useMutation(requestStudentMakeupExamMutation, {
    variables: { testCd, examWindow, educationPeriod, studentId },
    update: cache => saveMakeupRequestedStatus(cache, enrollmentId),
  })

  return data ? null : (
    <Modal
      body={
        <>
          <p>
            Once your makeup digital exam request has been processed, your original exam won’t be
            scored.
          </p>
          {error ? (
            <p className="cb-error-msg">
              An error occurred requesting your makeup exam. Please try again.
            </p>
          ) : null}
        </>
      }
      title="Request a Makeup"
      buttons={[
        { title: 'Cancel', dismiss: true },
        {
          title: 'Request a Makeup',
          primary: true,
          busy: loading,
          disabled: loading,
          click: () => {
            request()
          },
        },
      ]}
    />
  )
})

const MakeupButton = ({ title = 'Request a Makeup', ...props }) => {
  const triggerRef = useRef(null)

  return (
    <>
      <NakedButton
        style={{ padding: 0 }}
        ref={triggerRef}
        onClick={() => OpenModal(RequestMakeupModal, props, triggerRef.current)}
      >
        {title}
      </NakedButton>
    </>
  )
}

export default MakeupButton
