import { ButtonRow } from '@cb/apricot-react'
import { formatDateTime, DATETIME_FORMATS } from '@myap/ui-library'
import Consumer from '../../_common/apollo/Consumer'
import { getSettings } from '../../../appsync/actions/settings'
import GoToScoreSendsButton from './GoToScoreSendsButton'

function ScoreSendContent({ client, isModal = false, adjustHeight }) {
  const { systemDates } = getSettings(client)
  const { diSelectionEndDate } = systemDates.deadlines

  useEffect(() => {
    let isCurrent = true

    if (isCurrent && isModal && adjustHeight) {
      setTimeout(() => adjustHeight(), 350)
    }
    return () => {
      isCurrent = false
    }
  }, [isModal, adjustHeight])

  return (
    <>
      {isModal ? (
        <div className="cb-modal-content">
          <p>
            Each year you take AP Exams, you can have your AP score report sent for free to one
            recipient (a college, university, or scholarship organization). Your official score
            report includes the scores from all AP Exams taken this year and any AP Exams you&#39;ve
            previously taken, along with certain demographic information about you and other
            information you provide during testing. Schools use your official score report to
            determine credit or placement, if applicable. Colleges, universities, and scholarship
            programs that you send your score to may also use your information and official score
            report to contact you with information about admissions, educational, financial aid, and
            scholarship opportunities.
          </p>
          <p>
            To send your score report, go to the <strong>Free Score Sends</strong> page.
          </p>
          <p className="cb-margin-bottom-24">
            If you haven&#39;t finalized your decision on where you&#39;re attending college, select
            the school you think you&#39;re most likely to attend. You can change your recipient any
            time by{' '}
            <strong>{formatDateTime(diSelectionEndDate, DATETIME_FORMATS.longMonthDayYear)}</strong>
            . Your score report will be sent in July.
          </p>
        </div>
      ) : (
        <p>
          Each year you take AP Exams, you can have your AP score report sent for free to one
          recipient (a college, university, or scholarship organization). Your score report includes
          the scores from all AP Exams taken this year and any AP Exams you&#39;ve taken in previous
          years, along with certain demographic information about you and other information you
          provide during testing. To send your score, go to the <strong>Free Score Sends</strong>{' '}
          page. You may designate your recipient any time before{' '}
          <strong>{formatDateTime(diSelectionEndDate, DATETIME_FORMATS.longMonthDayYear)}</strong>.
        </p>
      )}
      {isModal ? (
        <div className="cb-modal-footer">
          <ButtonRow>
            <GoToScoreSendsButton />
          </ButtonRow>
        </div>
      ) : (
        <div className="display-flex cb-margin-top-24 justify-content-center">
          <GoToScoreSendsButton />
        </div>
      )}
    </>
  )
}

ScoreSendContent.displayName = 'ScoreSendContent'

export default Consumer(ScoreSendContent)
