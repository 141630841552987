import { useMutation } from '@apollo/client'
import { Error, Spinner } from '@myap/ui-library'
import { formatAppsyncErrors } from '../../../appsync/utils'

export default props => {
  const { Component, mutation, variables, showLoading, showError, update } = props
  const [action, { loading, error, data, client }] = useMutation(mutation, { variables, update })
  const msg = formatAppsyncErrors(error)
  return (
    <>
      {loading && showLoading ? <Spinner spinnerId="mutationSpinner" /> : null}
      {error && showError ? <Error title="Error loading data" message={msg} /> : null}
      <Component
        data={data}
        {...props}
        update={action}
        client={client}
        meta={{ loading, error: msg }}
      />
    </>
  )
}
