import { forwardRef, cloneElement } from 'react'
import { Text, isRequired, isCorrectLength } from '@myap/ui-library'
import { Modal, BlackButton, PrimaryButton } from '@cb/apricot-react'
import { querySectionDetails, setJoinCourseDetails } from '../../../../appsync/actions/student'
import {
  useAnalyticsStudentEnrollStep1Event,
  useAnalyticsStudentEnrollStep1ErrorEvent,
} from '../../../../hooks/analyticsHooks'
import { CONFIRM, ALREADY_ENROLLED_ERROR } from './constants'

import styles from '../joincourse.module.scss'

const EnterCode = forwardRef(
  ({ updateCode, setDisabled, enrollments, error: submitError }, ref) => {
    const [error, setError] = useState(submitError)
    const validate = value => {
      const transformed = value.toUpperCase().trim()
      const alreadyEnrolled = enrollments.find(
        e => e.joinCode === transformed || e.transferCode === transformed
      )
        ? ALREADY_ENROLLED_ERROR
        : undefined
      const required = isRequired(transformed)
      const correntLength = isCorrectLength(transformed, 6)
      return alreadyEnrolled || correntLength || required
    }

    useEffect(() => {
      if (!error) setError(submitError)
    }, [submitError])

    useEffect(() => {
      setDisabled(error !== undefined)
    }, [error])

    return (
      <div className={`${styles['enter-code']}`}>
        <Text
          name="enrollmentCode"
          id="enrollmentCode"
          aria-label="Enter join code"
          required={true}
          hideErrorIndicator={true}
          hideRequiredIndicator={true}
          placeholder="&mdash; &mdash; &mdash; &mdash; &mdash; &mdash;"
          maxLength={6}
          ref={ref}
          error={error}
          onBlur={({ relatedTarget, target }) => {
            const { dismiss } = (relatedTarget && relatedTarget.dataset) || {}
            if (dismiss !== 'modal' && !submitError) setError(validate(target.value))
          }}
          onChange={e => {
            const { value } = e.target
            const error = validate(value)
            updateCode(value.toUpperCase())
            setError(error)
          }}
        />
        <p>
          <strong>If you do not have a join code, ask your teacher.</strong>
        </p>
      </div>
    )
  }
)

const EnterCodeStep = props => {
  const { client, changeStep, enrollments } = props
  const [enrollmentCode, updateCode] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(true)
  // const [useAnimation, setUseAnimation] = useState(true)
  const useAnimation = true
  const inputRef = useRef(null)
  const body = cloneElement(<EnterCode />, {
    ref: inputRef,
    updateCode,
    enrollments,
    setDisabled,
    error,
  })

  useAnalyticsStudentEnrollStep1Event({ submitError: error, joinCode: enrollmentCode })
  useAnalyticsStudentEnrollStep1ErrorEvent({ submitError: error })

  const FooterActions = () => (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <BlackButton className="cb-margin-right-16" disabled={busy} onClick={() => setOpen(false)}>
        Cancel
      </BlackButton>
      <PrimaryButton
        disabled={disabled || busy}
        loading={busy}
        onClick={async () => {
          setError(undefined)
          await setBusy(true)

          const { error, data = {} } = await querySectionDetails(client, enrollmentCode)

          await setBusy(false)

          if (error) {
            inputRef.current.focus()
            setError(error)
          } else {
            setJoinCourseDetails(client, { enrollmentCode, sectionId: data.sectionId })
            // setUseAnimation(false)
            changeStep(CONFIRM)
          }
        }}
      >
        Submit
      </PrimaryButton>
    </div>
  )

  return (
    <Modal
      title="Enter Join Code to Enroll"
      open={open}
      closeAnimation={useAnimation}
      focusId="enrollmentCode"
      footer={<FooterActions />}
      shadowRoot
      modalNode
    >
      {body}
    </Modal>
  )
}

EnterCodeStep.displayName = 'Enter Code Step'

export default EnterCodeStep
