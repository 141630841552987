import {
  USER_PREVIEW_STUDENT,
  USER_PREVIEW_TEACHER,
  USER_COORDINATOR,
  USER_DISTRICT_ADMIN,
  USER_DISTRICT_SUPER_ADMIN,
  CMS_COORDINATOR,
  CMS_ADMIN,
} from '@myap/metadata'
import { profile, StatusPanel } from '@myap/ui-library'
import Banner from '../_common/banner/Banner'
import Courses from '../professional/courses/Courses'
import SchoolToggle from '../_common/schooltoggle/SchoolToggle'
import Resources from '../professional/resources/Resources'
import Query from '../_common/apollo/Query'
import Consumer from '../_common/apollo/Consumer'
import { getSettings } from '../../appsync/actions/settings'
import { updateQueryProfessionalFineTune } from '../../appsync/actions/professional'
import {
  adminQuery,
  adminStatusQuery,
  districtAdminSubscription,
  adminSubscription,
  coordinatorQuery,
  coordinatorStatusQuery,
  coordinatorSubscription,
} from '../../appsync/graphql/professional'
import TeacherTable from '../_common/courseaccordion/TeacherTable'
import Notifications from '../_common/content/notifications/Notifications'
import HorizontalLinkList from '../_common/horizontallinklist/HorizontalLinkList'
import { getPreviewList } from '../preview/utils'
import ProfessionalTimeline from '../professional/timeline/Timeline'
import Timeline from '../_common/content/timeline/Timeline'
import TimelineTeaser from '../_common/content/timeline/TimelineTeaser'

const getActionsByUserType = ({ user, districtOrg, parentOrgId, baseVariables }) => {
  const isCoord = user === USER_COORDINATOR
  const isDistrict = user === USER_DISTRICT_ADMIN || user === USER_DISTRICT_SUPER_ADMIN

  return {
    courseKey: isCoord || isDistrict ? 'getCoordinatorData' : 'getAdminData',
    courseQuery: isCoord || isDistrict ? coordinatorQuery : adminQuery,
    subscription: districtOrg
      ? districtAdminSubscription
      : isCoord || isDistrict
      ? coordinatorSubscription
      : adminSubscription,
    statusQuery: isCoord || isDistrict ? coordinatorStatusQuery : adminStatusQuery,
    cms: isCoord || isDistrict ? CMS_COORDINATOR : CMS_ADMIN,
    variables: districtOrg ? { ...baseVariables, parentOrgId } : baseVariables,
  }
}

function ProfessionalApp({
  data = [],
  showStatusPanel,
  showCourses = true,
  showTransitionContent,
  cms,
  statusQuery,
  variables,
}) {
  const { aproUrl } = profile()
  const anchorId = 'professionalCoursesAnchor'
  const headerStyles = showStatusPanel || !showCourses ? {} : { marginBottom: 0 }

  return (
    <Timeline
      userRole={cms}
      courses={data}
      component={({ events = [] }) => (
        <>
          <TimelineTeaser events={events} />
          <Notifications courses={data} userRole={cms} showAsDropdown={false} />
          <div className="container">
            {showTransitionContent ? null : (
              <div className="row">
                <div className="col-xs-12">
                  <h2 id={anchorId} className="cb-h4 cb-margin-top-48 cb-margin-bottom-16">
                    <a href={`${aproUrl}/dashboard`}>AP Registration and Ordering</a>
                  </h2>
                </div>
              </div>
            )}
            {showStatusPanel ? (
              <div className="row">
                <div className="col-xs-12">
                  <Query
                    query={statusQuery}
                    variables={variables}
                    Component={({ data }) =>
                      Object.keys(data).length ? <StatusPanel data={data} history={null} /> : null
                    }
                  />
                </div>
              </div>
            ) : null}
            {showCourses ? (
              <div className="row">
                <div className="col-xs-12">
                  <Courses courses={data} body={TeacherTable} />
                </div>
              </div>
            ) : null}
            <Resources
              courses={data}
              userRole={cms}
              showTransitionContent={showTransitionContent}
            />
            <ProfessionalTimeline
              events={events}
              anchorId={anchorId}
              anchorTitle="Ap Registration and Ordering"
            />
          </div>
        </>
      )}
    />
  )
}

export default Consumer(({ client, userRole }) => {
  const {
    selectedOrgId: orgId,
    educationPeriod: { code, isTransitionPeriod },
    roles,
  } = getSettings(client)
  const { showOrderData, needToCompletePartForm, parentOrgId, districtOrg } = roles.find(
    r => r.orgId === orgId
  )
  const baseVariables = { educationPeriod: code, orgId }
  const actions = getActionsByUserType({ user: userRole, districtOrg, parentOrgId, baseVariables })
  const previews = getPreviewList([USER_PREVIEW_TEACHER, USER_PREVIEW_STUDENT], client)

  return (
    <>
      <Banner
        subHeader={<SchoolToggle />}
        navigation={<HorizontalLinkList items={previews} withinBanner={true} />}
      />
      <Query
        query={actions.courseQuery}
        variables={{ ...actions.variables, filter: { showOrderData } }}
        requestStatusesEnabled={true}
        subscription={{
          document: actions.subscription,
          variables: actions.variables,
          updateQuery: (prev, next) =>
            updateQueryProfessionalFineTune(prev, next, actions.courseKey),
        }}
        skipQuery={needToCompletePartForm}
        Component={props => (
          <ProfessionalApp
            {...props}
            {...actions}
            variables={actions.variables}
            showCourses={!needToCompletePartForm}
            showStatusPanel={!needToCompletePartForm && showOrderData}
            showTransitionContent={needToCompletePartForm && isTransitionPeriod}
          />
        )}
      />
    </>
  )
})
