import { Modal } from '@cb/apricot-react'
import Consumer from '../../_common/apollo/Consumer'
import { getStudentEnrollments } from '../../../appsync/actions/student'
import { useAnalyticsStudentRegChangeEvent } from '../../../hooks/analyticsHooks'
import { SELECTED_YES } from './constants'

import styles from './register.module.scss'

const SuccessMessage = ({ enrollment = {} }) => {
  const { examIntent, courseName } = enrollment

  return (
    <div className={styles['register-success']}>
      {examIntent === SELECTED_YES ? (
        <div className="cb-h1">
          You are now registered for
          <br />
          the {courseName} exam!
        </div>
      ) : (
        <div className="cb-h2">
          You have confirmed that you are not registered for the {courseName} exam.
        </div>
      )}
    </div>
  )
}

export default Consumer(({ client, ...props }) => {
  const { courseEnrollments } = getStudentEnrollments(client)
  const enrollment = courseEnrollments.find(d => d.enrollmentId === props.enrollmentId)

  useAnalyticsStudentRegChangeEvent({ enrollment })

  return (
    <Modal
      open={true}
      title={`Register for ${enrollment.examStartTime ? 'Exam' : 'Assessment'}`}
      closeButtonLabel="OK"
      shadowRoot
      modalNode
    >
      <SuccessMessage enrollment={enrollment} />
    </Modal>
  )
})
