import { getGraphQLErrorMessage } from '@myap/ui-library'
import { getSettings, setPersonId } from './settings'
import {
  studentEnrollmentsQuery,
  sectionDetailsQuery,
  joinCourseDetailsQuery,
  studentInfoAndRegistrationQuery,
} from '../graphql/student'
import { MAKEUP_STATUS_REQUESTED } from '../../components/student/enrollment/statuses/utils'

const getStudentDataAttributes = client => {
  const {
    educationPeriod: { code },
  } = getSettings(client)

  return {
    query: studentInfoAndRegistrationQuery,
    variables: { code, isStudent: true, refresh: false },
  }
}

export const getStudentData = client => {
  try {
    const attributes = getStudentDataAttributes(client)
    const { getStudentInfoAndRegistration } = client.readQuery(attributes) || {}
    const hasRegistration = Object.keys(getStudentInfoAndRegistration)?.length > 0

    return {
      hasRegistration,
      attendingOrg: hasRegistration ? getStudentInfoAndRegistration.attendingOrg : {},
      grade: hasRegistration ? getStudentInfoAndRegistration.grade : null,
    }
  } catch (err) {
    console.info(err)
    return {}
  }
}

const getEnrollmentQueryAttributes = client => {
  const {
    educationPeriod: { code },
  } = getSettings(client)
  return { query: studentEnrollmentsQuery, variables: { code } }
}

export const getStudentEnrollments = client => {
  try {
    const attributes = getEnrollmentQueryAttributes(client)
    const { getStudentEnrollments = {} } = client.readQuery(attributes) || {}
    return getStudentEnrollments
  } catch (err) {
    console.info(err)
    return {}
  }
}

export const setStudentEnrollments = client => {
  try {
    const attributes = getEnrollmentQueryAttributes(client)
    client.writeQuery({
      ...attributes,
      data: {
        getStudentEnrollments: {
          scoreSendData: {
            diCode: null,
            userPromptType: null,
          },
          courseEnrollments: [],
          __typename: 'studentEnrollments',
        },
      },
    })
  } catch (err) {
    console.info(err)
  }
}

export const updateStudentRegistration = ({ client, data, code, refresh }) => {
  try {
    client.writeQuery({
      query: studentInfoAndRegistrationQuery,
      variables: { code, isRegistration: true, isStudent: true, refresh },
      data: {
        getStudentInfoAndRegistration: {
          ...data,
          __typename: 'studentInfoAndRegistration',
        },
      },
    })
    setPersonId(client, data.studentId)
    return data
  } catch (err) {
    console.info(err)
    return {}
  }
}

export const updateStudentInformation = ({ client, data, refresh }) => {
  try {
    const {
      educationPeriod: { code },
    } = getSettings(client)
    client.writeQuery({
      query: studentInfoAndRegistrationQuery,
      variables: { code, isProfile: true, isStudent: true, refresh },
      data: {
        getStudentInfoAndRegistration: {
          ...data,
          __typename: 'studentInfoAndRegistration',
        },
      },
    })
    setPersonId(client, data.studentId)
    return data
  } catch (err) {
    console.info(err)
    return {}
  }
}

export const updateStudentEnrollments = (cache, response, key) => {
  const {
    data: {
      [key]: {
        courseEnrollments,
        scoreSendData: { diCode, userPromptType },
      },
    },
  } = response
  const attributes = getEnrollmentQueryAttributes(cache)
  // console.log('updateStudentEnrollments (action) - enrollment complete, update cache')
  cache.writeQuery({
    ...attributes,
    data: {
      getStudentEnrollments: {
        scoreSendData: {
          diCode,
          userPromptType,
          __typename: 'scoreSend',
        },
        courseEnrollments,
        __typename: 'studentEnrollments',
      },
    },
  })
}

export const saveStudentScoreSend = (cache, response) => {
  const {
    data: {
      saveStudentScoreSend: { diCode, userPromptType },
    },
  } = response
  const attributes = getEnrollmentQueryAttributes(cache)

  if (saveStudentScoreSend) {
    const {
      getStudentEnrollments: { courseEnrollments },
    } = cache.readQuery(attributes)

    cache.writeQuery({
      ...attributes,
      data: {
        getStudentEnrollments: {
          scoreSendData: {
            diCode,
            userPromptType,
            __typename: 'scoreSend',
          },
          scoreSendSaved: true,
          courseEnrollments,
          __typename: 'studentEnrollments',
        },
      },
    })
  }
}

export const querySectionDetails = async (client, enrollmentCode) => {
  try {
    const {
      data: { getSectionDetails },
    } = await client.query({
      query: sectionDetailsQuery,
      variables: { enrollmentCode },
    })
    return { data: getSectionDetails }
  } catch (err) {
    return { error: getGraphQLErrorMessage(err) }
  }
}

export const getJoinCourseDetails = client => {
  try {
    const {
      getJoinCourseDetails: { __typename, ...details },
    } = client.readQuery({ query: joinCourseDetailsQuery })
    return details
  } catch (err) {
    console.info(err)
    return {}
  }
}

export const setJoinCourseDetails = (
  client,
  {
    enrollmentCode,
    sectionId,
    livedInLanguageCountry = null,
    languageRegularlySpoken = null,
    isMove = false,
    enrollmentId = null,
  }
) => {
  client.writeQuery({
    query: joinCourseDetailsQuery,
    data: {
      getJoinCourseDetails: {
        enrollmentCode,
        sectionId,
        livedInLanguageCountry,
        languageRegularlySpoken,
        isMove,
        enrollmentId,
        __typename: 'joinCourseDetails',
      },
    },
  })
}

export const getSectionDetails = client => {
  try {
    const {
      getJoinCourseDetails: { enrollmentCode },
    } = client.readQuery({ query: joinCourseDetailsQuery })
    const { getSectionDetails } = client.readQuery({
      query: sectionDetailsQuery,
      variables: { enrollmentCode },
    })
    return { ...getSectionDetails, enrollmentCode }
  } catch (err) {
    console.info(err)
    return {}
  }
}

export const updateQueryStudent = (prev, { subscriptionData }, KEY) => {
  if (!subscriptionData.data) return prev

  const previous = prev[KEY]
  const { data: updatedEnrollments } = subscriptionData.data[KEY]
  const update = previous.courseEnrollments.map(enrollment => {
    const updates = updatedEnrollments.find(e => e.testCd === enrollment.testCd) || {}
    return {
      ...enrollment,
      ...updates,
    }
  })

  return { [KEY]: { ...previous, courseEnrollments: update } }
}

export const saveMakeupRequestedStatus = (cache, enrollmentId) => {
  const attributes = getEnrollmentQueryAttributes(cache)
  const { getStudentEnrollments } = cache.readQuery(attributes)
  const updated = getStudentEnrollments.courseEnrollments.map(c => {
    const isUpdateEnrollment = c.enrollmentId === enrollmentId
    return isUpdateEnrollment ? { ...c, makeupStatus: MAKEUP_STATUS_REQUESTED } : c
  })

  cache.writeQuery({
    ...attributes,
    data: {
      getStudentEnrollments: {
        ...getStudentEnrollments,
        courseEnrollments: updated,
      },
    },
  })
}
