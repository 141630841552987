import { Modal } from '@cb/apricot-react'
import Consumer from '../../../_common/apollo/Consumer'
import { getSectionDetails } from '../../../../appsync/actions/student'
import { useAnalyticsStudentEnrollStep1WrongCodeEvent } from '../../../../hooks/analyticsHooks'

const Message = () => (
  <>
    <p>
      If you were expecting a different course or exam, double check that you entered the join code
      correctly.
    </p>
    <p>If you aren&#39;t sure what to do next, ask your teacher how to enroll online.</p>
  </>
)

const ExitMessageStep = Consumer(({ client }) => {
  const { teachers } = getSectionDetails(client)

  useAnalyticsStudentEnrollStep1WrongCodeEvent()

  return (
    <Modal
      title={`No, I am not in this ${teachers?.length ? 'course' : 'exam'}`}
      openAnimation={false}
      open={true}
      shadowRoot
      modalNode
    >
      <Message />
    </Modal>
  )
})

ExitMessageStep.displayName = 'Exit Message Step'

export default ExitMessageStep
