import { closestToDateIndex, getEndOfWeek } from '@myap/ui-library'
import { isExamStandard, isExamSecondSemester } from '@myap/metadata'
import TimelineContent from '../../_common/content/timeline/TimelineContent'
import ProgressSlider from '../../_common/progressslider/ProgressSlider'
import Consumer from '../../_common/apollo/Consumer'
import TimelineHeader from '../../_common/content/timeline/TimelineHeader'
import { getSettings } from '../../../appsync/actions/settings'
import { getStudentAssignments } from '../../_common/content/timeline/utils'
import { EventsByDay, EventsByWeek } from '../../_common/content/timeline/Events'
import Timeline from '../../_common/content/timeline/Timeline'
import WeekEvents from './WeekEvents'

import styles from '../../_common/content/timeline/timeline.module.scss'
import {
  EVENT_SECOND_DECISION,
  EVENT_STANDARD_DECISION,
} from '../../_common/content/timeline/constants'

const HORIZONTAL_VIEW = 'H'
const VERTICAL_VIEW = 'V'
const title = 'Week at a Glance'

const TimelineBody = ({ isHorizontal, byDay, byWeek }) => {
  const startIndex = closestToDateIndex(
    getEndOfWeek(new Date()),
    byWeek.map(e => e.endDate)
  )

  return isHorizontal ? (
    <ProgressSlider
      ariaLabel={title}
      sliderId="studentTimeline"
      className={styles['student-timeline']}
      items={byWeek}
      visibleCount={1}
      activeItem={startIndex}
    >
      <WeekEvents />
    </ProgressSlider>
  ) : (
    <TimelineContent events={byDay} isHorizontal={false} />
  )
}

const StudentTimeline = props => {
  const { anchorId, enrollments, events, educationPeriod } = props
  const [view, setView] = useState(HORIZONTAL_VIEW)
  const [filters, setFilters] = useState([])
  const filterByCourse = data =>
    filters.length ? data.filter(d => filters.find(f => !d.testCd || f === d.testCd)) : data
  const options = enrollments
    ? enrollments.reduce((opts, e) => {
        const exists = opts.find(o => o.value === e.testCd)
        return exists ? opts : [...opts, { value: e.testCd, label: e.courseName }]
      }, [])
    : []
  const isHorizontal = view === HORIZONTAL_VIEW
  const filteredEvents = filterByCourse(events)
  const { allDates, dueDatesOnly } = getStudentAssignments(filterByCourse(enrollments))
  const byDay = EventsByDay([...filteredEvents, ...dueDatesOnly], false)
  const byWeek = EventsByWeek([...filteredEvents, ...allDates], educationPeriod)

  return byDay.length || byWeek.length ? (
    <div className={`${styles['timeline-container']} cb-margin-top-48`}>
      <div className="container">
        <TimelineHeader
          view={view}
          setView={setView}
          buttons={[
            { value: HORIZONTAL_VIEW, label: 'At a Glance', icon: 'cb-nav-links' },
            { value: VERTICAL_VIEW, label: 'Full Timeline', icon: 'cb-menu' },
          ]}
          title={title}
          filterProps={{ update: setFilters, options }}
          isHorizontal={isHorizontal}
          anchorId={anchorId}
        />
        <div className="row">
          <div className="col-xs-12">
            <TimelineBody byDay={byDay} byWeek={byWeek} isHorizontal={isHorizontal} />
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Consumer(({ client, enrollments, anchorId }) => {
  const { educationPeriod, systemDates } = getSettings(client)
  const commonAttrs = { anchorId, educationPeriod, enrollments }
  const [examDecisions, examDates] = enrollments.reduce(
    (acc, enrollment) => {
      const [decisions, dates] = acc
      const { registrationDeadline, examStartTime, testCd } = enrollment
      const currentStandardDecisions = decisions[EVENT_STANDARD_DECISION] || []
      const currentSecondSemesterDecisions = decisions[EVENT_SECOND_DECISION] || []
      const standardDecisionUpdate = isExamStandard(enrollment)
        ? [...currentStandardDecisions, registrationDeadline]
        : currentStandardDecisions
      const secondSemesterDecisionsUpdate = isExamSecondSemester(enrollment)
        ? [...currentSecondSemesterDecisions, registrationDeadline]
        : currentSecondSemesterDecisions

      return [
        registrationDeadline
          ? {
              [EVENT_STANDARD_DECISION]: standardDecisionUpdate,
              [EVENT_SECOND_DECISION]: secondSemesterDecisionsUpdate,
            }
          : decisions,
        examStartTime ? { ...dates, [testCd]: [{ examDate: examStartTime, testCd }] } : dates,
      ]
    },
    [[], {}]
  )
  const preloadedDates = { ...systemDates, examDecisions, examDates }

  return (
    <Timeline
      userRole="student"
      courses={enrollments}
      preloadedDates={preloadedDates}
      component={({ events }) => <StudentTimeline {...commonAttrs} events={events} />}
    />
  )
})
