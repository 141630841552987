import { useQuery } from '@apollo/client'
import { isEmpty } from '@myap/ui-library'
import {
  districtStatusQuery,
  districtStatusSubscription,
} from '../../../appsync/graphql/professional'
import { updateQueryDistrictStatus } from '../../../appsync/actions/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useDistrictStatusQuery = ({ orgId, educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
    subscribeToMore,
    client,
  } = useQuery(districtStatusQuery, {
    variables: { orgId, educationPeriod },
    skip: !orgId || !educationPeriod || otherSkipConditions,
  })
  const data = {
    ...(rawData?.getDistrictStatus?.data ?? {}),
    lastUpdated: rawData?.getDistrictStatus?.lastUpdated,
  }
  const error = rawError ? formatAppsyncErrors(rawError) : null

  if (!isEmpty(data)) {
    subscribeToMore({
      document: districtStatusSubscription,
      variables: { orgId, educationPeriod },
      updateQuery: updateQueryDistrictStatus,
    })
  }

  return { data, loading, error }
}

export default useDistrictStatusQuery
