import { getSettings } from '../../../appsync/actions/settings'

const Flag = ({ client, feature, renderEnabled, renderDisabled, children }) => {
  const { inactiveFeatures } = getSettings(client)
  const featureEnabled = inactiveFeatures.findIndex(f => f === feature) === -1

  if (featureEnabled && children) {
    return children
  }

  if (featureEnabled) {
    if (renderEnabled) {
      return renderEnabled
    }
  } else if (renderDisabled) {
    return renderDisabled
  }

  return null
}

export default Flag
