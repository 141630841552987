import { NakedButton } from '@cb/apricot-react'
import { getElementMeasurements, getWindowOffsetTop } from '@myap/ui-library'
import Consumer from '../../_common/apollo/Consumer'
import { setRole, getSettings } from '../../../appsync/actions/settings'
import { getShadowRootNode } from '../../../utilities/dom'

import styles from './banner.module.scss'
import Resources from '../../_common/content/resources/Resources'

const Banner = ({ client, name = '', content, previousRole }) => {
  const numOfScreens = content.length
  const previewRef = useRef(null)
  const [screen, setScreen] = useState(0)
  const [measurements, setMeasurements] = useState({})
  const [windowOffsetTop, setWindowOffsetTop] = useState(0)
  const [scrollingDown, setScrollingDown] = useState(true)
  const [isSticky, setSticky] = useState(false)
  const [isTransitioning, setTransition] = useState(false)
  const getScreenElement = screen => getShadowRootNode().querySelector(`#previewScreen${screen}`)

  const scrollTo = (top = 0) => {
    window.scrollTo({ top: windowOffsetTop + top, behavior: 'smooth' })
    setTransition(true)
    setTimeout(() => setTransition(false), 1000)
  }

  const updateScreen = newScreen => {
    const elem = getScreenElement(newScreen)

    if (newScreen < screen && !elem) {
      const prevElem = getScreenElement(newScreen - 1)
      const prevElemMeasurements = getElementMeasurements(prevElem)
      scrollTo(prevElemMeasurements.top)
    } else if (elem) {
      const elemMeasurements = getElementMeasurements(elem)
      scrollTo(elemMeasurements.top - measurements.height)
    }

    setScreen(newScreen)
  }

  const updateScroll = () => {
    const currentOffsetTop = getWindowOffsetTop()
    const scrollingDown = windowOffsetTop < currentOffsetTop
    setWindowOffsetTop(currentOffsetTop)
    setScrollingDown(scrollingDown)
    setSticky(currentOffsetTop > measurements.top)
  }

  useEffect(() => {
    const measurements = getElementMeasurements(previewRef.current)
    setMeasurements(measurements)
    window.addEventListener('scroll', updateScroll)
    return () => window.removeEventListener('scroll', updateScroll)
  }, [content, windowOffsetTop])

  useEffect(() => {
    if (!isTransitioning && screen !== numOfScreens - 1 && scrollingDown) {
      const { top } = getElementMeasurements(getScreenElement(screen + 1))

      if (top - measurements.height <= 0) setScreen(screen + 1)
    } else if (!isTransitioning && screen && !scrollingDown) {
      const { top } = getElementMeasurements(getScreenElement(screen - 1))
      if (top > 0) setScreen(screen - 1)
    }
  }, [windowOffsetTop])

  return (
    <>
      <div
        className={`${styles['preview-banner']} ${isSticky ? styles.sticky : ''}`}
        ref={previewRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-6">
              <NakedButton
                icon="left"
                iconLeft
                light
                onClick={() => {
                  window.scrollTo(0, 0)
                  setRole(client, previousRole)
                }}
              >
                Back
              </NakedButton>
            </div>
            <div className="col-xs-6 cb-align-right">
              <strong>Viewing sample {name.toLowerCase()} screen</strong>
            </div>
          </div>
          {numOfScreens ? (
            <div className="row">
              <div className={`${styles['preview-banner-content']} col-sm-offset-2 col-sm-8`}>
                <h3 dangerouslySetInnerHTML={{ __html: content[screen].title }} />
                <p dangerouslySetInnerHTML={{ __html: content[screen].content }} />
                <NakedButton
                  icon="left"
                  iconLeft
                  onClick={() => updateScreen(screen - 1)}
                  disabled={!screen}
                  aria-label="Go to previous sample content"
                  light
                />
                <span>
                  {screen + 1} of {numOfScreens}
                </span>
                <NakedButton
                  icon="right"
                  onClick={() => updateScreen(screen + 1)}
                  disabled={screen === numOfScreens - 1}
                  aria-label="Go to next sample content"
                  light
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {isSticky ? (
        <div aria-hidden={true} style={{ height: measurements.height, display: 'hidden' }} />
      ) : null}
    </>
  )
}

export default Consumer(({ client, name }) => {
  const { roles, selectedOrgId } = getSettings(client)
  const { role } = roles.find(r => r.orgId === selectedOrgId) || {}
  const userRole = name.toLowerCase()

  return (
    <Resources
      userRole={userRole}
      Component={content => {
        const previewContent = content
          ? Object.keys(content).reduce((arr, key) => {
              const id = `${userRole}Preview`
              const index = key.startsWith(id) ? parseInt(key.replace(id, ''), 10) - 1 : null
              if (index !== null) arr[index] = content[key]
              return arr
            }, [])
          : []

        return <Banner title={name} content={previewContent} client={client} previousRole={role} />
      }}
    />
  )
})
