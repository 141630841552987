import { BareModal } from '@cb/apricot-react'
import ProfileForm from '../../profileandreg/ProfileForm'
import { REGISTER } from './constants'
import { adjustModalHeight } from '../../../../utilities/dom'
import { useAnalyticsStudentRegStep1Event } from '../../../../hooks/analyticsHooks'

const ID = 'studentProfileModal'

const StudentProfileModal = ({ changeStep }) => {
  const useAnimation = true
  const isStandAlone = typeof changeStep !== 'function'

  useAnalyticsStudentRegStep1Event()

  const adjustHeight = () => adjustModalHeight(`#${ID}`)

  return (
    <BareModal
      modalId={ID}
      open={true}
      openAnimation={useAnimation}
      closeAnimation={useAnimation}
      clickOverlayToClose={!isStandAlone}
      escClose={!isStandAlone}
      shadowRoot
      modalNode
    >
      <ProfileForm changeStep={changeStep} nextStep={REGISTER} adjustHeight={adjustHeight} />
    </BareModal>
  )
}

StudentProfileModal.displayName = 'Student Profile Modal'

export default StudentProfileModal
