import { Glyph, CloseButton } from '@cb/apricot-react'
import { Spinner } from '@myap/ui-library'
import Query from '../../apollo/Query'
import Consumer from '../../apollo/Consumer'
import Mutation from '../../apollo/Mutation'
import {
  saveDeletedNotificationMutation,
  userPreferencesQuery,
} from '../../../../appsync/graphql/content'
import { getSettings } from '../../../../appsync/actions/settings'
import { updateNotifications } from '../../../../appsync/actions/content'
import { filterByCourseCategory, filterByTestCd } from '../utils'
import { getCMSNotifications } from '../../../../rest/cms'

import styles from './notifications.module.scss'

const Notification = ({ content, id, icon, educationPeriod }) => {
  const variables = { id, educationPeriod }
  const processedIcon = icon?.startsWith('cb-') ? icon.slice(3) : icon

  return (
    <Mutation
      mutation={saveDeletedNotificationMutation}
      update={(cache, response) => updateNotifications(cache, response, variables)}
      Component={({ update, meta: { loading, error } }) => (
        <div
          className={`cb-notification cb-notification-dismissible cb-white-bg ${styles.notification}`}
          role="region"
        >
          <div className="cb-notification-container">
            <div className="cb-notification-header">
              <Glyph name={processedIcon ?? 'megaphone'} circular />
              <span dangerouslySetInnerHTML={{ __html: content }} />
              <CloseButton
                className={styles['btn-dismiss']}
                onClick={() => update({ variables })}
                label="Close notification"
              />
            </div>
          </div>
        </div>
      )}
    />
  )
}

const Notifications = ({ notifications, data, educationPeriod, showAsDropdown }) => {
  const [open, setOpen] = useState(!showAsDropdown)
  const { deletedNotifications = [] } = data || {}
  const notificationsWithoutDeletes = notifications.filter(
    n => !deletedNotifications.find(d => d === n.id)
  )
  const count = notificationsWithoutDeletes.length
  const id = 'userNotifications'

  return count ? (
    <>
      {showAsDropdown ? (
        <button
          type="button"
          className={styles['notifications-button']}
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          aria-controls={open ? id : null}
        >
          <Icon className={styles['notifications-icon-megaphone']} name="megaphone" />
          <span>
            {count} New Notification{count > 1 ? 's' : ''}
          </span>
          <Icon name={open ? 'minus' : 'plus'} />
        </button>
      ) : null}

      {open ? (
        <div
          role="region"
          aria-label="Notifications"
          id={id}
          className={`cb-gray5-bg ${showAsDropdown ? styles['notifications-dropdown'] : ''} ${
            styles.notifications
          }`}
        >
          <div className="container">
            {notificationsWithoutDeletes.map(msg => (
              <Notification key={msg.id} {...msg} educationPeriod={educationPeriod} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  ) : null
}

export default Consumer(({ client, courses, userRole, showAsDropdown = true }) => {
  const [notifications, setNotifications] = useState([])
  const {
    educationPeriod: { code },
  } = getSettings(client)

  useEffect(() => {
    const fetch = async () => {
      const data = await getCMSNotifications(userRole)
      const filtered = filterByTestCd(courses, filterByCourseCategory(courses, data))
      setNotifications(filtered)
    }

    fetch()
  }, [])

  return notifications.length ? (
    <Query
      query={userPreferencesQuery}
      variables={{ educationPeriod: code }}
      Component={props =>
        !props.meta.loading ? (
          <Notifications
            {...props}
            showAsDropdown={showAsDropdown}
            educationPeriod={code}
            notifications={notifications}
          />
        ) : null
      }
    />
  ) : null
})
