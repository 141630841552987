import { formatDate } from '@myap/ui-library'
import { Checkmark } from '../../../_common/apricot/Icons'
import DAPButton from './DAPButton'
import styles from '../enrollment.module.scss'

const DigitalTestingApp = ({ appInstalled, availableDate, isDigitalAppAvailable }) => (
  <div className={styles['install-status']}>
    <h5>Digital Testing App</h5>
    {!isDigitalAppAvailable ? (
      <>Available {formatDate(availableDate, 'MMMM d')}</>
    ) : appInstalled ? (
      <>
        App Installed <Checkmark />
      </>
    ) : (
      <DAPButton
        title="Download App"
        subtitle="(If you've already installed, log in to the app to verify.)"
        textOnChrome="Launch the app and log in."
        textOnMobile="Open the app on the device you're testing on and log in."
      />
    )}
  </div>
)
export default DigitalTestingApp
