import { ApolloConsumer } from '@apollo/client'

export default Component => {
  return props => (
    <ApolloConsumer>
      {client => {
        return <Component {...props} client={client} />
      }}
    </ApolloConsumer>
  )
}
