/**
 * Link that opens new tab or window on link click while ignoring
 * other event effects.
 */
export default ({ url, children }) => (
  <a
    href={url}
    onClick={e => {
      e.preventDefault()
      window.open(url)
    }}
  >
    {children}
  </a>
)
