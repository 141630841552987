import { Error, Spinner } from '@myap/ui-library'
import { useQuery } from '@apollo/client'
import { getQueryDataWithoutKey, formatAppsyncErrors } from '../../../appsync/utils'

export default props => {
  const {
    Component,
    query,
    variables,
    requestStatusesEnabled,
    showLoading,
    showError,
    subscription,
    skipQuery,
    onCompleted,
  } = props
  const { loading, error, data, subscribeToMore, client } = useQuery(query, {
    variables,
    onCompleted,
  })
  const queryData = getQueryDataWithoutKey(data) || {}
  const msg = error ? formatAppsyncErrors(error) : null

  if (skipQuery) return <Component {...props} />

  if ((loading && requestStatusesEnabled) || showLoading)
    return <Spinner spinnerId="querySpinner" />

  if ((error && requestStatusesEnabled) || showError)
    return <Error title="Error loading data" message={msg} useWrapper={true} />

  if (subscription && queryData) subscribeToMore(subscription)

  return <Component data={queryData} {...props} client={client} meta={{ loading, error: msg }} />
}
