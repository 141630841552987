import { userPreferencesQuery } from '../graphql/content'

export const updateNotifications = (cache, response, variables) => {
  const key = 'getUserPreferences'
  const query = { query: userPreferencesQuery, variables }
  const { [key]: preferences } = cache.readQuery(query)
  const { deletedNotifications = [] } = preferences || {}

  cache.writeQuery({
    ...query,
    data: {
      [key]: {
        ...(preferences || {}),
        deletedNotifications: [...deletedNotifications, variables.id],
      },
    },
  })
}
