import AWS from 'aws-sdk'
import { iam, profile, parseDateTime } from '@myap/ui-library'

// TODO add retry policy if not there
const INTERVAL_SECONDS = 15 // 5 secs
const MAX_RETRY = 20 // total ellapsed time: 5min
let retryCount = 0
export const getStudentPracticeResults = async (educationPeriod, testCd, onComplete) => {
  try {
    const { studentPracticeBucketName } = profile()
    const { getTempAWSCreds, getTempAWSCredsSubject } = iam()
    const awsCredentials = getTempAWSCreds('apfym')
    const s3 = new AWS.S3({ credentials: awsCredentials })
    const response = await s3
      .getObject({
        Bucket: studentPracticeBucketName,
        Key: `${getTempAWSCredsSubject()}/${educationPeriod}/${testCd}/results.pdf`,
        ResponseCacheControl: 'no-store, max-age=0',
      })
      .promise()
    const { Body, ContentType, LastModified } = response
    const blob = new Blob([Body], { type: ContentType })
    const link = URL.createObjectURL(blob)
    const data = { link, date: parseDateTime(LastModified) }
    onComplete && onComplete(data)
    return data
  } catch (err) {
    if (retryCount < MAX_RETRY) {
      retryCount += 1
      setTimeout(() => getStudentPracticeResults(educationPeriod, testCd, onComplete), INTERVAL_SECONDS * 1000)
    }
  }
}
