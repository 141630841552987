import { Icon } from '@cb/apricot-react'
import Consumer from '../_common/apollo/Consumer'
import PreviewBanner from '../preview/banner/Banner'
import Banner from '../_common/banner/Banner'
import SchoolToggle from '../_common/schooltoggle/SchoolToggle'
import CourseCards from '../teacher/coursecards/CourseCards'
import Resources from '../teacher/resources/Resources'
import APClassroomNavigation from '../teacher/navigation/APClassroomNavigation'
import { getSettings } from '../../appsync/actions/settings'
import { getTeacherAssignments } from '../_common/content/timeline/utils'
import { flagCourseTypes, bodyTitle } from '../_common/content/utils'
import ToggleInactiveLink from '../_common/actions/ToggleInactiveLink'
import { processJSON } from '../preview/utils'
import ProfessionalTimeline from '../professional/timeline/Timeline'
import Timeline from '../_common/content/timeline/Timeline'
import TimelineTeaser from '../_common/content/timeline/TimelineTeaser'

const userRole = 'teacher'

import sample from '../preview/jsons/teacher.json'

export default Consumer(({ client }) => {
  const name = 'Teacher'
  const {
    educationPeriod: { academicYrStartDate, academicYrEndDate },
  } = getSettings(client)
  const { courses, timeline } = processJSON(sample, academicYrStartDate, academicYrEndDate)
  const anchorId = 'previewScreen1'
  const { hasPreAP, hasAP } = flagCourseTypes(courses)
  const title = bodyTitle({ hasAP, hasPreAP })

  return (
    <Timeline
      userRole={userRole}
      courses={courses}
      component={({ events = [], dates }) => (
        <>
          <PreviewBanner name={name} />
          <Banner
            bannerId="previewScreen0"
            name={name}
            subHeader={<SchoolToggle preview={true} />}
            navigation={
              <APClassroomNavigation
                courses={courses}
                hasAP={hasAP}
                hasPreAP={hasPreAP}
                preview={true}
              />
            }
          />
          {hasAP ? <TimelineTeaser events={events} /> : null}
          <div id={anchorId} className="container cb-margin-top-48">
            <div className="row">
              <div className="col-md-8 col-sm 6 col-xs-12">
                <h2 className="cb-h4 cb-margin-bottom-24">Explore {title} Classroom Resources</h2>
              </div>
              <div className="col-md-4 col-sm 6 col-xs-12">
                <ToggleInactiveLink inactive={true} href="#" className="cb-roboto-bold cb-hover">
                  My AP Profile <Icon name="east" />
                </ToggleInactiveLink>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <CourseCards
                  examDates={dates.examDates}
                  userRole={userRole}
                  courses={courses}
                  preview={true}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <Resources
                  courses={courses}
                  userRole={userRole}
                  preview={true}
                  id="previewScreen3"
                />
              </div>
            </div>
            <ProfessionalTimeline
              courses={courses}
              events={timeline}
              assignments={getTeacherAssignments(courses)}
              anchorId={anchorId}
            />
          </div>
        </>
      )}
    />
  )
})
